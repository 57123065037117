import { useCallback, useMemo } from "react";

import {
  BranchReconciliationStatus,
  CollaboratorDayShift,
  ExtendedCollaboratorDayShift,
  ICollaborator,
  IJob,
  ISimplifiedBranchCashReconciliation,
  ISimplifiedBranchCashReconciliationResponse,
  IWeekShiftBase,
} from "../types";
import { useAppSelector, useAuthHook, useBranchHook } from "../../hooks";
import {
  HandleErrorsService,
  SimplifiedBranchCashReconciliationService,
  WeekShiftService,
} from "../../services";
import { dateIntoStringDate, getMonday, getSunday } from "../helpers";
import { fireSwalError, fireSwalSuccess } from "../../helpers";
import { useNavigate } from "react-router-dom";

const initialEmptyValues: IWeekShiftBase = {
  startingDate: dateIntoStringDate(getMonday()),
  endingDate: dateIntoStringDate(getSunday()),
  shifts: [],
};

export const useSimplifiedBranchCashReconciliationHook = () => {
  const service = new SimplifiedBranchCashReconciliationService();
  const branches = useAppSelector((state) => state.branches);
  const { currentUser } = useAuthHook();
  const navigate = useNavigate();
  const fetchInitialSimplifiedBranchCashReconciliations = async () => {
    // Fetch latest record for each branch in parallel
    const promises = branches.branches.map((branch) =>
      service.getAll({
        branchId: branch.id,
        sort_by: "transactionDatetime",
        direction: "desc",
        limit: 1,
      })
    );

    const results = await Promise.all(promises);
    const latestRecords = results.flatMap((result) => result.data);
    const transformedRecords = latestRecords.map((record) =>
      service.transformResponseToEntity(record)
    );

    return transformedRecords;
  };

  const getOpeningBranchCashReconciliation = useCallback(
    async (
      branchId: string,
      setCashInDrawerStart: (cashInDrawerStart: number) => void
    ) => {
      const simplifiedBranchCashReconciliation = await service.getAll({
        branchId,
        status: BranchReconciliationStatus.COMPLETED,
        sort_by: "transactionDatetime",
        direction: "desc",
        limit: 1,
      });

      setCashInDrawerStart(
        simplifiedBranchCashReconciliation.data[0].closingCash
      );
    },

    [service]
  );

  const getCurrentBranchCashReconciliation = async (
    branchNameOrId: string
  ): Promise<ISimplifiedBranchCashReconciliation | { error: string }> => {
    const branch = branches.branches.find(
      (branch) => branch.name === branchNameOrId
    );
    console.log({ branch });
    const currentDate = new Date();

    let simplifiedBranchCashReconciliation:
      | ISimplifiedBranchCashReconciliation
      | undefined;

    // existent branch
    if (branch) {
      const lastClosedElement = await service.getAll({
        branchId: branch.id,
        status: BranchReconciliationStatus.COMPLETED,
        sort_by: "transactionDatetime",
        direction: "desc",
        limit: 1,
      });

      const lastClosingCash = lastClosedElement.data[0].closingCash ?? 0;

      simplifiedBranchCashReconciliation = {
        branchId: branch.id,
        cashierId: currentUser?.uid!,
        transactionDate: currentDate,
        transactionDatetime: currentDate,
        cashInDrawerStart: lastClosingCash,
        qvetCashInDrawer: 0,
        cashInDrawerEnd: 0,
        cashTransfer: 0,
        closingCash: 0,
        status: BranchReconciliationStatus.PENDING,
      };
    } else {
      try {
        const response = await service.getById(branchNameOrId);
        simplifiedBranchCashReconciliation = service.transformResponseToEntity(
          response.data
        );
      } catch {
        return { error: "Branch not found" };
      }
    }

    if (simplifiedBranchCashReconciliation) {
      return simplifiedBranchCashReconciliation;
    } else {
      return { error: "Branch not found" };
    }
  };

  const getBranchInitialData = async (
    branchName: string
  ): Promise<ISimplifiedBranchCashReconciliation> => {
    const branchId = branches.branches.find(
      (branch) => branch.name === branchName
    )?.id;
    console.log({ branchId });
    const lastClosedElement = await service.getAll({
      branchId: branchId!,
      status: BranchReconciliationStatus.COMPLETED,
      sort_by: "transactionDatetime",
      direction: "desc",
      limit: 1,
    });
    console.log({ lastClosedElement });
    const lastClosingCash = lastClosedElement.data?.[0]?.closingCash || 0;
    console.log({ lastClosingCash });
    return {
      branchId: branchId!,
      cashierId: currentUser?.uid!,
      transactionDate: new Date(),
      transactionDatetime: new Date(),
      cashInDrawerStart: lastClosingCash,
      qvetCashInDrawer: 0,
      cashInDrawerEnd: lastClosingCash,
      cashTransfer: 0,
      closingCash: lastClosingCash,
      status: BranchReconciliationStatus.PENDING,
    };
  };

  const saveSimplifiedBranchCashReconciliation = async (
    simplifiedBranchCashReconciliation: ISimplifiedBranchCashReconciliation
  ) => {
    try {
      if (simplifiedBranchCashReconciliation.id) {
        simplifiedBranchCashReconciliation.transactionDatetime =
          simplifiedBranchCashReconciliation.transactionDate;
        const transformed = service.transformEntityToRequest(
          simplifiedBranchCashReconciliation
        );
        await service.update(
          simplifiedBranchCashReconciliation.id,
          transformed
        );
      } else {
        simplifiedBranchCashReconciliation.transactionDatetime = new Date();
        simplifiedBranchCashReconciliation.transactionDate = new Date();
        simplifiedBranchCashReconciliation.status =
          BranchReconciliationStatus.COMPLETED;
        const transformed = service.transformEntityToRequest(
          simplifiedBranchCashReconciliation
        );
        await service.create(transformed);
      }

      await fireSwalSuccess("Simplified branch cash reconciliation saved");
      navigate("/dashboard/simplified-branch-cash-reconciliation");
    } catch (error) {
      const errorMessage = HandleErrorsService.handleError(error);
      await fireSwalError(errorMessage);
    }
  };

  return {
    fetchInitialSimplifiedBranchCashReconciliation:
      fetchInitialSimplifiedBranchCashReconciliations,
    getOpeningBranchCashReconciliation,
    getCurrentBranchCashReconciliation,
    getBranchInitialData,
    saveSimplifiedBranchCashReconciliation,
  };
};
