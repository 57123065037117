import {
  IBaseEntity,
  IBaseResponseEntity,
  IPopulatedProperty,
} from "./base.entity";

export interface ISimplifiedBranchCashReconciliationBase extends IBaseEntity {
  transactionDate: string | Date; // Flexible to allow Date in app and string in API
  transactionDatetime: string | Date;
  cashInDrawerStart: number;
  qvetCashInDrawer: number;
  cashInDrawerEnd: number;
  cashTransfer: number;
  closingCash: number;
  status: BranchReconciliationStatus;
  notes?: string;
  branchId: string | IPopulatedProperty;
  cashierId: string | IPopulatedProperty;
}

export enum BranchReconciliationStatus {
  "PENDING" = "PENDING",
  "COMPLETED" = "COMPLETED",
}

export interface ISimplifiedBranchCashReconciliationRequest
  extends ISimplifiedBranchCashReconciliationBase {
  branchId: string;
  cashierId: string;
  transactionDate: string;
  transactionDatetime: string;
}

export interface ISimplifiedBranchCashReconciliationResponse
  extends ISimplifiedBranchCashReconciliationBase {
  id: string;
  branchId: IPopulatedProperty;
  cashierId: IPopulatedProperty;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface ISimplifiedBranchCashReconciliation
  extends ISimplifiedBranchCashReconciliationBase {
  id?: string;
  branchId: string;
  cashierId: string;
  transactionDate: Date; // date as YYYY-MM-DD
  transactionDatetime: Date; // date as YYYY-MM-DD HH:MM:SS
}
