import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { SimplifiedBranchCashReconciliationForm } from "../components";
import { SimplifiedBranchCashReconciliationService } from "../../../../services";
import { useAuthHook } from "../../../../hooks";

export const SimplifiedBranchCashReconciliationEdit = () => {
  // *** GLOBALS *** //
  const { id } = useParams();

  // *** SELECTORS *** //

  // *** STATES *** //

  // *** DATA INITIALIZATION *** //
  const [initialData, setInitialData] =
    useState<ISimplifiedBranchCashReconciliation | null>(null);
  const [error, setError] = useState<string | null>(null);
  // *** CUSTOM HOOKS *** //
  const { isAdminOrManager } = useAuthHook();

  // *** COMPUTED VALUES *** //
  // *** HANDLERS *** //
  // *** HELPERS *** //

  // *** EFFECTS *** //

  useEffect(() => {
    const getSimplifiedBranchCashReconciliation = async () => {
      try {
        const service = new SimplifiedBranchCashReconciliationService();
        const response = await service.getById(id!);
        const transformed = service.transformResponseToEntity(response.data);
        setInitialData(transformed);
      } catch (error) {
        setError("Error fetching simplified branch cash reconciliation");
      }
    };
    getSimplifiedBranchCashReconciliation();
  }, [id]);

  if (!isAdminOrManager) return <>You are not authorized to access this page</>;

  if (error) return <>Error: {error}</>;

  return (
    <>
      {initialData && (
        <SimplifiedBranchCashReconciliationForm initialData={initialData} />
      )}
      <pre>{JSON.stringify({ initialData, isAdminOrManager }, null, 2)}</pre>
    </>
  );
};
