import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useAppDispatch } from "../../../../hooks";
import { useFormStatusHook } from "../../../../shared/hooks/useFormStatus.hook";
import { WeekShiftService } from "../../../../services";
import {
  buildWeekShiftParams,
  changeWeekShiftDates,
} from "../../../../shared/helpers";
import { CollaboratorDayShift, IWeekShiftBase } from "../../../../shared/types";
import { fireSwalError, fireSwalSuccess } from "../../../../helpers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const getLastTenMondays = (): string[] => {
  const mondays: string[] = [];
  let current = dayjs().day(1); // Start with the last Monday

  for (let i = 0; i < 6; i++) {
    mondays.push(current.format("YYYY-MM-DD"));
    current = current.subtract(1, "week");
  }

  return mondays;
};

type Props = {
  weekShift: IWeekShiftBase;
  isCollapsed: boolean;
  toggleSection: (section: "copyCalendar") => void;
  fetchWeekShift: () => void;
  handleCopyShifts: (shifts: CollaboratorDayShift[]) => void;
};

export const ModelsSection: React.FC<Props> = ({
  weekShift,
  isCollapsed,
  toggleSection,
  fetchWeekShift,
  handleCopyShifts,
}) => {
  const [hasId, sethasId] = useState<string>("");
  const { startingDate } = weekShift;
  const service = new WeekShiftService();
  const dispatch = useAppDispatch();
  const [selectedCopyModel, setSelectedCopyModel] = useState<string>("");
  const [selectedSaveModel, setSelectedSaveModel] = useState<string>("");
  const [models, setModels] = useState<string[]>([]);

  const modelsOptions = models.map((model) => ({
    label: model,
    value: model,
  }));

  const datesOptions = getLastTenMondays().map((date) => ({
    label: date,
    value: date,
  }));

  const options = [...modelsOptions, ...datesOptions];

  const fetchModels = async () => {
    const response = await service.getAll({ isModel: "true" });
    const models = response.data;
    if (models.length > 0) {
      setModels(models.map((model) => model.modelName!));
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (weekShift.id) {
      sethasId(weekShift.id);
    } else {
      sethasId("");
    }
  }, [weekShift]);

  const { handleUnsavedChanges, hasUnsavedChanges } = useFormStatusHook();

  const handleCopyChange = (event: SelectChangeEvent<string>) => {
    setSelectedCopyModel(event.target.value as string);
  };

  const handleSaveChange = async (event: SelectChangeEvent<string>) => {
    setSelectedSaveModel(event.target.value as string);
  };

  const handleCopyClick = async () => {
    const params = buildWeekShiftParams(selectedCopyModel);
    await handleUnsavedChanges(
      async () => {
        const response = await service.getAll(params);
        const weekShifts = response.data;
        const weekShift = weekShifts[0];
        weekShift.isModel = false;
        weekShift.modelName = "";
        const newWeekShift = changeWeekShiftDates(weekShift, startingDate);
        handleCopyShifts(newWeekShift.shifts);
        setSelectedCopyModel("");
      },
      () => {}
    );
  };

  const handleSaveClick = async () => {
    const id = weekShift.id;
    if (!id) {
      return await fireSwalError(
        "You can only set as model already saved weekshifts"
      );
    }
    await handleUnsavedChanges(async () => {
      await service.update(id, {
        ...weekShift,
        isModel: true,
        modelName: selectedSaveModel,
      });
      fetchWeekShift();
      fetchModels();
    });
    setSelectedSaveModel("");
    await fireSwalSuccess("Model saved successfully");
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Handling models</Typography>
          <IconButton onClick={() => toggleSection("copyCalendar")}>
            {!isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "primary.main" }}
          >
            {weekShift.modelName}
          </Typography>
        </Box>
      </Box>
      <Collapse in={!isCollapsed}>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth>
                <InputLabel id="copy-calendar-label">Select Model</InputLabel>
                <Select
                  labelId="copy-calendar-label"
                  value={selectedCopyModel}
                  onChange={handleCopyChange}
                  label="Select Model"
                >
                  {options.map((model) => (
                    <MenuItem key={model.value} value={model.value}>
                      {model.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCopyClick}
                fullWidth
                sx={{
                  height: "100%",
                }}
                disabled={!selectedCopyModel}
              >
                Copy
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth>
                <InputLabel id="save-calendar-label">Select Model</InputLabel>
                <Select
                  labelId="save-calendar-label"
                  value={selectedSaveModel}
                  onChange={handleSaveChange}
                  label="Select Model"
                >
                  {Array.from({ length: 5 }, (_, i) => (
                    <MenuItem key={`Model ${i + 1}`} value={`Model ${i + 1}`}>
                      {`Model ${i + 1}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              md={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSaveClick}
                fullWidth
                disabled={!selectedSaveModel || !hasId}
                sx={{
                  height: "100%",
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
