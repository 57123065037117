import dayjs from "dayjs";
import {
  IPublicHolidaysEntity,
  IPublicHolidaysRequest,
  IPublicHolidaysResponse,
} from "../shared/types";

import { BaseService } from "./base.service";
import { getUtcMidnightDayjs, toMexicoStartOfDay } from "../shared/helpers";

export class PublicHolidaysService extends BaseService<
  IPublicHolidaysResponse,
  IPublicHolidaysRequest
> {
  constructor() {
    super("/public-holidays");
  }

  public transformEntityToRequest(
    entity: IPublicHolidaysEntity
  ): IPublicHolidaysRequest {
    return {
      ...entity,
      publicHolidays: entity.publicHolidays.map((publicHoliday) =>
        getUtcMidnightDayjs(publicHoliday).toISOString()
      ),
    };
  }

  public transformResponseToEntity(
    response: IPublicHolidaysResponse
  ): IPublicHolidaysEntity {
    return {
      ...response,
      publicHolidays: response.publicHolidays.map((publicHoliday) =>
        dayjs(publicHoliday)
      ),
      createdAt: new Date(response.createdAt),
      updatedAt: new Date(response.updatedAt),
    };
  }
}
