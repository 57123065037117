import React from "react";
import { PublicHolidayForm } from "../components/";
import { IPublicHolidaysEntity } from "../../../../../shared/types";
import dayjs from "dayjs";

export const NewPublicHolidays = () => {
  const currentYear = new Date().getFullYear();
  const initialValues: IPublicHolidaysEntity = {
    year: currentYear,
    publicHolidays: [
      dayjs(`${currentYear}-01-01`),
      dayjs(`${currentYear}-02-05`),
      dayjs(`${currentYear}-03-18`),
      dayjs(`${currentYear}-05-01`),
      dayjs(`${currentYear}-09-16`),
      dayjs(`${currentYear}-11-18`),
      dayjs(`${currentYear}-12-25`),
    ],
  };

  return <PublicHolidayForm initialValues={initialValues} />;
};
