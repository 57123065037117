import dayjs from "dayjs";
import {
  TimeOffRequest,
  TimeOffRequestEntity,
  TimeOffRequestDayjsEntity,
  TimeOffRequestRequest,
  TimeOffRequestResponse,
  CollaboratorTimeOffOverview,
  TimeOffType,
} from "../shared/types/time-off-request.types";
import { BaseService } from "./base.service";
import { toMexicoStartOfDay } from "../shared/helpers";
import { ApiResponseList, ApiResponseOne, AxiosParams } from "../shared/types";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";

const API_URL = "/time-off-requests";
export class TimeOffRequestService extends BaseService<
  TimeOffRequestResponse,
  TimeOffRequestRequest
> {
  constructor() {
    super(API_URL);
  }

  public async getCollaboratorTimeOffOverview(
    collaboratorId: string,
    params?: AxiosParams
  ): Promise<ApiResponseOne<CollaboratorTimeOffOverview>> {
    const response = await hvpAuthenticatedApi.get<
      ApiResponseOne<CollaboratorTimeOffOverview>
    >(`${this.API_URL}/collaborators/time-off-overview/${collaboratorId}`, {
      params,
    });
    return response.data;
  }

  public async getCollaboratorsTimeOffOverview(
    params?: AxiosParams
  ): Promise<ApiResponseList<CollaboratorTimeOffOverview>> {
    const response = await hvpAuthenticatedApi.get<
      ApiResponseList<CollaboratorTimeOffOverview>
    >(`${this.API_URL}/collaborators/time-off-overview`, { params });
    return response.data;
  }

  public transformResponseToEntity(
    response: TimeOffRequestRequest
  ): TimeOffRequestEntity {
    return {
      ...response,
      approvedAt: response.approvedAt
        ? new Date(response.approvedAt)
        : undefined,
      requestedAt: new Date(response.requestedAt),
      requestedDays: response.requestedDays.map((day) => new Date(day)),
    };
  }

  public transformEntityToMxEntity(
    timeOffRequest: TimeOffRequestEntity
  ): TimeOffRequestDayjsEntity {
    return {
      ...timeOffRequest,
      requestedDays: timeOffRequest.requestedDays.map((day) =>
        toMexicoStartOfDay(day)
      ),
      approvedAt: timeOffRequest.approvedAt
        ? toMexicoStartOfDay(timeOffRequest.approvedAt)
        : undefined,
      requestedAt: toMexicoStartOfDay(timeOffRequest.requestedAt),
    };
  }
  public transformResponseToMxEntity(
    response: TimeOffRequestRequest
  ): TimeOffRequestDayjsEntity {
    return this.transformEntityToMxEntity(
      this.transformResponseToEntity(response)
    );
  }

  public isTimeOffTypeVacation(timeOffType: TimeOffType): boolean {
    return (
      timeOffType === TimeOffType.Vacation ||
      timeOffType === TimeOffType.PersonalLeave
    );
  }
}
