import { toMexicoStartOfDay } from "../shared/helpers";
import {
  IWeekShift,
  IWeekShiftBase,
  IWeekShiftPayrollEntity,
  IWeekShiftResponse,
} from "../shared/types";
import { BaseService } from "./base.service";

export class WeekShiftService extends BaseService<IWeekShift, IWeekShiftBase> {
  constructor() {
    super("/week-shifts");
  }

  public transformResponseToMxEntity(
    response: IWeekShiftResponse
  ): IWeekShiftPayrollEntity {
    return {
      ...response,
      startingDate: toMexicoStartOfDay(response.startingDate),
      endingDate: toMexicoStartOfDay(response.endingDate),
      shifts: response.shifts.map((shift) => ({
        ...shift,
        startingTime: shift.startingTime
          ? toMexicoStartOfDay(shift.startingTime)
          : undefined,
        endingTime: shift.endingTime
          ? toMexicoStartOfDay(shift.endingTime)
          : undefined,
        shiftDate: toMexicoStartOfDay(shift.shiftDate),
      })),
    };
  }
}
