import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { ISimplifiedBranchCashReconciliation } from "../../../shared/types";

import { useAuthHook } from "../../../hooks";
import { useSimplifiedBranchCashReconciliationHook } from "../../../shared/hooks";
import { SimplifiedBranchCashReconciliationTable } from "./components/SimplifiedBranchCashReconciliationTable";

export const SimplifiedBranchCashReconciliationIndex: React.FC = () => {
  // *****GLOBALS***** //

  // *****STATES***** //

  const [
    simplifiedBranchCashReconciliations,
    setSimplifiedBranchCashReconciliations,
  ] = useState<ISimplifiedBranchCashReconciliation[]>([]);

  // *****CUSTOM HOOKS***** //

  const { fetchInitialSimplifiedBranchCashReconciliation } =
    useSimplifiedBranchCashReconciliationHook();

  const isAdmin = useAuthHook().isAdminOrManager;

  // *****HELPERS***** //

  // *****EFFECTS***** //

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchInitialSimplifiedBranchCashReconciliation();
      setSimplifiedBranchCashReconciliations(response);
    };
    fetchData();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        mb={2}
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 2,
        }}
      >
        <Typography variant="h5" fontWeight="bold" flex={1}>
          Branch Cash Reconciliation
        </Typography>
      </Box>
      <Divider />
      <Box mt={3}>
        <SimplifiedBranchCashReconciliationTable
          reconciliations={simplifiedBranchCashReconciliations}
          linkTo="branch"
        />
      </Box>
      {isAdmin && (
        <pre>
          {JSON.stringify(simplifiedBranchCashReconciliations, null, 2)}
        </pre>
      )}
    </Box>
  );
};
