// TODO

import { Dayjs } from "dayjs";
import { IBaseEntity } from "./base.entity";

export interface TimeOffRequest {
  _id?: string;
  approvedAt?: Date;
  collaborator?: string;
  createdAt?: Date;
  createdBy?: string;
  collaboratorNote?: string;
  managerNote?: string;
  requestedAt?: Date;
  requestedDays: Date[];
  status?: TimeOffStatus;
  timeOffType: TimeOffType;
  approvedBy?: string;
  updatedAt?: Date;
  updatedBy?: string;
}

// todo homologate to attendance-report.type.ts
export enum AttendanceType {
  ordinary = "Ordinary",
  SimulatedAbsence = "Simulated Absence",
  restDay = "Rest Day",
  vacation = "Vacation",
  sickLeaveIMSS = "IMSS Disability",
  JustifyAbsenceByCompany = "Justified by Company",
  unjustifiedAbsence = "Unjustified Absence",
  dayLeave = "Permission Day (Deducted)",
  NotCounted = "Not Counted",
  PersonalLeave = "Asuntos propios",
  shiftToBeCompensated = "Jornada a reponer",
  AuthorizedUnjustifiedAbsence = "Unjustified Absence Authorized",
  LatePermission = "Late Permission",
  EarlyLeavePermission = "Early Leave Permission",
}

export interface AttendanceTypeInfo {
  description: string;
  name: string;
}

export enum TimeOffType {
  Vacation = "Vacation",
  PersonalLeave = "Asuntos propios",
  SickLeaveIMSS = "Incapacidad por IMSS",
  ShiftToBeCompensated = "Jornada a reponer",
  JustifiedAbsenceByCompany = "Inasistencia justificada por la empresa",
  AuthorizedUnjustifiedAbsence = "Falta injustificada autorizada",
  EarlyLeavePermission = "Permiso de salida anticipada",
  LatePermission = "Permiso de llegar tarde",
  CompensationShift = "Reposición de jornada",
}

export const timeOffRequestTypeDescriptions: Record<TimeOffType, string> = {
  [TimeOffType.Vacation]: "Jornada de vacaciones",
  [TimeOffType.PersonalLeave]:
    "Asuntos propios. Se interpretan como vacaciones",
  [TimeOffType.SickLeaveIMSS]: "Incapacidad por IMSS",
  [TimeOffType.ShiftToBeCompensated]:
    "Jornada a reponer. Es necesario crear otro time off request con la jornada en la que se repondrá",
  [TimeOffType.JustifiedAbsenceByCompany]:
    "Inasistencia justificada por la empresa",
  [TimeOffType.AuthorizedUnjustifiedAbsence]:
    "Falta injustificada autorizada. Tiene los mismos efectos que la falta injustificada pero se toma en cuenta que el colaborador avisó",
  [TimeOffType.EarlyLeavePermission]: "Permiso de salida anticipada",
  [TimeOffType.LatePermission]: "Permiso de llegar tarde",
  [TimeOffType.CompensationShift]: "Reposición de jornada",
};

export enum TimeOffStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
  Canceled = "Canceled",
}

export interface CollaboratorTimeOffOverview {
  collaboratorId: string;
  totalVacationDays: number; // a) The number of vacation days the collaborator has a right to take
  vacationsTaken: Date[]; // b) Vacations taken by the collaborator (an array of dates)
  vacationsRequested: Date[]; // c) Vacations requested but not approved (an array of dates)
  remainingVacationDays: number; // d) Number of vacation days left for the collaborator
  dateTimeOffRequests: DateTimeOffRequest[];
  lastAnniversaryDate: Date; // e) The last anniversary date of the collaborator
  legalVacationDays: number; // f) The number of legal vacation days the collaborator has a right to take
  remainingLegalVacationDays: number;
  remainingcurrentYearVacationDays: number;
}

export interface DateTimeOffRequest {
  date: Date;
  id: string;
  timeOffType: TimeOffType;
  status: TimeOffStatus;
  collaborator: string | { name: string; id: string; imgUrl: string };
}

export interface DateTimeOffRequestCollaborator {
  date: Date;
  id: string;
  timeOffType: TimeOffType;
  status: TimeOffStatus;
  collaborator: { name: string; id: string; imgUrl: string };
}

export interface TimeOffRequestBaseNew extends IBaseEntity {
  // _id?: string;
  approvedAt?: Date | string | Dayjs;
  approvedBy?: string;
  collaborator: string;
  collaboratorNote?: string;
  createdBy?: string;
  managerNote?: string;
  requestedAt: Date | string | Dayjs;
  requestedDays: Date[] | string[] | Dayjs[];
  status: TimeOffStatus;
  timeOffType: TimeOffType;
  updatedBy?: string;
}

export interface TimeOffRequestRequest extends TimeOffRequestBaseNew {
  id?: string;
  approvedAt?: string;
  requestedAt: string;
  createdAt?: string;
  updatedAt?: string;
  requestedDays: string[];
}

export interface TimeOffRequestResponse extends TimeOffRequestBaseNew {
  id: string;
  approvedAt?: string;
  requestedAt: string;
  createdAt: string;
  updatedAt: string;
  requestedDays: string[];
}

export interface TimeOffRequestEntity extends TimeOffRequestBaseNew {
  requestedDays: Date[];
  approvedAt?: Date;
  requestedAt: Date;
}

export interface TimeOffRequestDayjsEntity extends TimeOffRequestBaseNew {
  requestedDays: Dayjs[];
  approvedAt?: Dayjs;
  requestedAt: Dayjs;
}
