import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { MenuRoute, ModuleMenu } from "../../../components/menu/ModuleMenu";
import { SimplifiedBranchCashReconciliationRouter } from "./SimplifiedBranchCashReconciliationRouter";
import { useAuthHook } from "../../../hooks";

export const SimplifiedBranchCashReconciliationLayout = () => {
  const isLoading = false;
  const { isAdminOrManager } = useAuthHook();

  const routes: MenuRoute[] = [
    { name: "Main", path: "" },
    { name: "History", path: "history" },
    { name: "Urban", path: "branch/Urban" },
    { name: "Harbor", path: "branch/Harbor" },
    { name: "Montejo", path: "branch/Montejo" },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>

      <Grid item xs={12}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <SimplifiedBranchCashReconciliationRouter />
        )}
      </Grid>
    </Grid>
  );
};
