import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../../../shared";
import {
  calculateBranchAndWeekendHours,
  calculateTotalHoursCovered,
  filterTimeOffDates,
  formatToInteger,
  getExcludedTimeOffDates,
  isSaturdayOrSunday,
} from "../../../../shared/helpers";
import {
  CollaboratorDayShift,
  ICollaborator,
  TimeOffRequestResponse,
  TimeOffStatus,
  TimeOffType,
} from "../../../../shared/types";
import { CollaboratorDayShiftEdit } from "./CollaboratorDayShiftEdit";
import { CollaboratorDayShiftView } from "./CollaboratorDayShiftView";

type Props = {
  collaborator: ICollaborator;
  toggleEditMode: (collaboratorId: string) => void;
  isEditing: boolean;
  weekDates: string[];
  shifts: CollaboratorDayShift[];
  startingDate: string;
  setShifts: React.Dispatch<React.SetStateAction<CollaboratorDayShift[]>>;
  totalShifts: CollaboratorDayShift[];
  timeOffRequests: TimeOffRequestResponse[];
};

export const CollaboratorWeekShift = ({
  collaborator,
  toggleEditMode,
  isEditing,
  weekDates,
  shifts,
  startingDate,
  setShifts,
  totalShifts,
  timeOffRequests,
}: Props) => {
  const scheduledHours = calculateTotalHoursCovered(shifts);
  const { weeklyHours } = collaborator;
  const { urbanHours, harborHours, montejoHours, weekendHours } =
    calculateBranchAndWeekendHours(totalShifts);
  const totalBranchHours = urbanHours + harborHours + montejoHours;
  const workingDayHours = weeklyHours ? weeklyHours / 6 : 8;

  const filteredTimeOffDates = filterTimeOffDates(
    timeOffRequests,
    startingDate
  );

  const excludedTimeOffDates = filteredTimeOffDates.filter(
    (timeOff) =>
      timeOff.timeOffType === TimeOffType.Vacation ||
      timeOff.timeOffType === TimeOffType.PersonalLeave
  );

  const excludedHours = excludedTimeOffDates.length * workingDayHours;
  const requiredHours = weeklyHours
    ? weeklyHours - excludedHours
    : 48 - excludedHours;

  const scheduleMatch = weeklyHours === scheduledHours;

  return (
    <Box
      key={collaborator.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: "350px",
        border: "1px solid lightgrey",
        padding: "10px",
        borderRadius: "8px",
        gap: 2,
      }}
    >
      {/* HEADER */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: 4,
        }}
      >
        <Typography variant="h6">{collaborator.col_code}</Typography>

        <Button
          size="small"
          onClick={() => toggleEditMode(collaborator.id!)}
          color={isEditing ? "error" : "primary"}
        >
          {isEditing ? "Cancel" : "Edit"}
        </Button>
      </Box>
      {/* SUMMARY */}
      <Box
        sx={{
          padding: 2,
          border: "1px solid lightblue",
          borderRadius: 2,
          width: "100%",
          backgroundColor: scheduleMatch ? SUCCESS_COLOR : ERROR_COLOR,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Req
            </Typography>
            <Typography variant="body2">
              {formatToInteger(requiredHours)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Sche
            </Typography>
            <Typography variant="body2">
              {formatToInteger(scheduledHours)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Missing Hours
            </Typography>
            <Typography variant="body2">
              {formatToInteger(requiredHours - scheduledHours)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* SCHEDULE */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        {weekDates.map((day, dayIndex) => {
          if (isEditing) {
            return (
              <CollaboratorDayShiftEdit
                key={dayIndex}
                day={day}
                startingDate={startingDate}
                dayIndex={dayIndex}
                collaborator={collaborator}
                shifts={shifts}
                setShifts={setShifts}
              />
            );
          }
          return (
            <CollaboratorDayShiftView
              key={dayIndex}
              day={day}
              startingDate={startingDate}
              dayIndex={dayIndex}
              collaborator={collaborator}
              shifts={shifts}
            />
          );
        })}
      </Box>
      {/* Updated Footer */}
      <Box
        sx={{
          padding: 2,
          border: "1px solid lightblue",
          borderRadius: 2,
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {/* Current Week Summary */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: "bold" }}>
            Current Week
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Weekly Hours
              </Typography>
              <Typography variant="body2">
                {formatToInteger(weeklyHours ?? 0)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Excluded Hours
              </Typography>
              <Typography variant="body2">
                {formatToInteger(excludedHours)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Scheduled
              </Typography>
              <Typography variant="body2">
                {formatToInteger(scheduledHours)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Missing
              </Typography>
              <Typography
                variant="body2"
                color={
                  requiredHours - scheduledHours !== 0 ? "error" : "success"
                }
              >
                {formatToInteger(requiredHours - scheduledHours)}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Branch Hours Summary */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: "bold" }}>
            Last 6 Weeks Summary Averages
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Weekend
              </Typography>
              <Typography variant="body2">
                {formatToInteger(weekendHours / 6)}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatToInteger(weekendHours)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Urban
              </Typography>
              <Typography variant="body2">
                {formatToInteger(urbanHours / 6)}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {Number((urbanHours / totalBranchHours) * 100).toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Harbor
              </Typography>
              <Typography variant="body2">
                {formatToInteger(harborHours / 6)}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {Number((harborHours / totalBranchHours) * 100).toFixed(2)}%
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color="textSecondary">
                Montejo
              </Typography>
              <Typography variant="body2">
                {formatToInteger(montejoHours / 6)}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {Number((montejoHours / totalBranchHours) * 100).toFixed(2)}%
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Time Off Dates */}
        <Box>
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: "bold" }}>
            Time Off Dates
          </Typography>
          {filteredTimeOffDates.map((timeOff) => (
            <Typography variant="body2" key={timeOff.day.toString()}>
              {timeOff.timeOffType}: {timeOff.day.format("DD-MMM-YYYY")}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
