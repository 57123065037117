import { NavigateBefore, NavigateNext } from "@mui/icons-material/";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DropdownField } from "../../../../components/dropdowns/DropdownField";
import {
  MONTHS_OPTIONS,
  getOptionsFromEnumOld,
  getYearOptions,
} from "../../../../helpers/fieldHelpers";
import { TaskStatsDashboard } from "./TaskStatsDashboard";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import { DateField } from "@mui/x-date-pickers/DateField";

import dayjs from "dayjs";
import {
  FUTUREDATE,
  OLDDATE,
  getNewEndDate,
} from "../../../../helpers/dateHelpers";

export enum ViewType {
  General = "general",
  Year = "year",
  Month = "month",
  Custom = "custom",
}

export interface SelectOption {
  value: string | number;
  label: string;
}

export const TaskStats = () => {
  const [startDate, setStartDate] = useState<Date>(new Date(1900, 1, 1));
  const [endDate, setEndDate] = useState<Date>(new Date(2100, 11, 31));
  const [viewType, setViewType] = useState<ViewType>(ViewType.Month);

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const yearOptions = getYearOptions();

  const viewOptions = getOptionsFromEnumOld(ViewType);
  const { tasks } = useAppSelector((state) => state.taskReducer);

  const handleViewChange = (event: SelectChangeEvent<string>) => {
    setViewType(event.target.value as ViewType);
  };

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setYear(parseInt(event.target.value as string));
  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setMonth(parseInt(event.target.value as string));
  };

  const handleYearChangeByNumber = (change: number) => {
    setYear((prevYear) => prevYear + change);
  };

  const handleMonthChangeByNumber = (change: number) => {
    let yearChange = 0;
    setMonth((prevMonth) => {
      let newMonth = prevMonth + change;
      if (newMonth < 0) {
        newMonth = 11;
        yearChange = -1;
      } else if (newMonth > 11) {
        newMonth = 0;
        yearChange = 1;
      }
      return newMonth;
    });
    setYear((prevYear) => prevYear + yearChange);
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    setStartDate(date.toDate());
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (!date) return;
    setEndDate(date.toDate());
  };

  useEffect(() => {
    switch (viewType) {
      case ViewType.Year: {
        setStartDate(new Date(year, 0, 1));
        setEndDate(new Date(year, 11, 31));

        break;
      }
      case ViewType.Month: {
        setStartDate(new Date(year, month, 1));
        setEndDate(new Date(year, month + 1, 0));
        break;
      }

      case ViewType.General: {
        setStartDate(new Date(OLDDATE));
        setEndDate(new Date(FUTUREDATE));
        break;
      }

      case ViewType.Custom: {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 15);
        setStartDate(new Date(newDate));
        setEndDate(new Date());
        break;
      }

      default:
        setStartDate(new Date(OLDDATE));
        setEndDate(new Date(FUTUREDATE));
        break;
    }
  }, [year, month, viewType]);

  const newEndDate = getNewEndDate(endDate);

  return (
    <Box>
      <Box>
        <Box display="flex" justifyContent="space-between" margin={2}>
          <Box flex="1" display="flex" justifyContent="center" padding={4}>
            <DropdownField
              options={viewOptions}
              selectedValue={viewType}
              handleSelectChange={handleViewChange}
              label="View"
            />
          </Box>
          <Box
            flex="1"
            display="flex"
            justifyContent="space-between"
            padding={4}
            gap={1}
            alignItems="center"
          >
            {/* CUSTOM */}
            {viewType === ViewType.Custom && (
              <Box
                display="flex"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
              >
                <DateField
                  label="Start Date"
                  value={dayjs(startDate)}
                  onChange={handleStartDateChange}
                  format="MM-DD-YYYY"
                />
                <DateField
                  label="End Date"
                  value={dayjs(endDate)}
                  onChange={handleEndDateChange}
                  format="MM-DD-YYYY"
                />
              </Box>
            )}
            {/* YEAR */}
            {viewType === ViewType.Year && (
              <>
                <Button onClick={() => handleYearChangeByNumber(-1)}>
                  <NavigateBefore />
                </Button>
                <DropdownField
                  options={yearOptions}
                  selectedValue={year.toString()}
                  handleSelectChange={handleYearChange}
                  label="View"
                />
                <Button onClick={() => handleYearChangeByNumber(1)}>
                  <NavigateNext />
                </Button>
              </>
            )}
            {/* MONTH */}
            {viewType === ViewType.Month && (
              <>
                <Button onClick={() => handleMonthChangeByNumber(-1)}>
                  <NavigateBefore />
                </Button>
                <DropdownField
                  options={yearOptions}
                  selectedValue={year.toString()}
                  handleSelectChange={handleYearChange}
                  label="View"
                />
                <DropdownField
                  options={MONTHS_OPTIONS}
                  selectedValue={month.toString()}
                  handleSelectChange={handleMonthChange}
                  label="View"
                />
                <Button onClick={() => handleMonthChangeByNumber(1)}>
                  <NavigateNext />
                </Button>
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" margin={1}>
          <TaskStatsDashboard
            tasks={tasks}
            startDate={startDate}
            endDate={newEndDate}
            viewType={viewType}
          />
        </Box>
      </Box>
    </Box>
  );
};
