import { createAsyncThunk } from "@reduxjs/toolkit";
import { fireSwalError } from "../../helpers";
import { BaseService, HandleErrorsService } from "../../services";
import { ApiResponseUpdate, AxiosParams, IBaseEntity } from "../types";

export const handleAsyncThunkError = (error: any, rejectWithValue: any) => {
  fireSwalError(HandleErrorsService.handleError(error));
  return rejectWithValue(error);
};

interface CustomMethods<B, T> {
  [key: string]: (item: B) => Promise<ApiResponseUpdate<T>> | any;
}

interface createCRUDThunksParams<T, B> {
  service: BaseService<T, B>;
  resourceName: string;
  customMethods?: CustomMethods<B, T>;
}
export const createCRUDThunks = <T extends IBaseEntity, B extends IBaseEntity>({
  service,
  resourceName,
  customMethods = {},
}: createCRUDThunksParams<T, B>) => {
  const fetchItems = createAsyncThunk(
    `${resourceName}/fetchItems`,
    async (params: AxiosParams | undefined = {}, { rejectWithValue }) => {
      try {
        const data = await service.getAll(params);
        return data.data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const getItemById = createAsyncThunk(
    `${resourceName}/getItemById`,
    async (id: string, { rejectWithValue }) => {
      try {
        const data = await service.getById(id);
        return data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const createItem = createAsyncThunk(
    `${resourceName}/createItem`,
    async (item: B, { rejectWithValue }): Promise<T> => {
      try {
        const data = await service.create(item);
        return data.data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const updateItem = createAsyncThunk(
    `${resourceName}/updateItem`,
    async (item: B, { rejectWithValue }) => {
      try {
        const data = customMethods.update
          ? await customMethods.update(item)
          : await service.update(item.id!, item);
        return data.data;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  const deleteItem = createAsyncThunk(
    `${resourceName}/deleteItem`,
    async (id: string, { rejectWithValue }) => {
      try {
        await service.delete(id);
        return id;
      } catch (error) {
        return handleAsyncThunkError(error, rejectWithValue);
      }
    }
  );

  // Registra otros métodos personalizados si existen
  const customThunks = Object.keys(customMethods).reduce((acc, methodName) => {
    acc[methodName] = createAsyncThunk(
      `${resourceName}/${methodName}`,
      async (item: B, { rejectWithValue }) => {
        try {
          return await customMethods[methodName](item);
        } catch (error) {
          return handleAsyncThunkError(error, rejectWithValue);
        }
      }
    );
    return acc;
  }, {} as { [key: string]: any });

  return {
    fetchItems,
    getItemById,
    createItem,
    updateItem,
    deleteItem,
    ...customThunks,
  };
};
