import React, { useEffect } from "react";
import { CircularProgress, Container, Grid } from "@mui/material";

import { MenuRoute, ModuleMenu } from "../../../components";
import { WebAppRole } from "../../../shared/types";
import { TimeOffRequestThunks } from "../../../store/thunks";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { TimeOffRequestRouter } from "./TimeOffRequestRouter";

const routes: MenuRoute[] = [
  {
    name: "Home",
    path: "",
  },
  {
    name: "Request a time off",
    path: "request-vacations",
  },
  {
    name: "Calendar",
    path: "calendar",
  },
  {
    name: "Request Status",
    path: "requests-status",
  },
  {
    name: "User Profile",
    path: "user-profile",
  },
  {
    name: "Approve",
    path: "approval",
    authorizedRoles: [WebAppRole.admin, WebAppRole.manager],
  },
  {
    name: "Create",
    path: "create",
    authorizedRoles: [WebAppRole.admin, WebAppRole.manager],
  },
];

export const TimeOffRequestsLayout: React.FC = () => {
  // const { startLoadingInitialState } = useTimeOffRequestsStore();
  const { isAllLoading } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(TimeOffRequestThunks.getCollaboratorsTimeOffOverview({}));

    return () => {};
  }, []);

  console.log(isAllLoading);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>
      <Grid item xs={12}>
        {isAllLoading ? <CircularProgress /> : <TimeOffRequestRouter />}
      </Grid>
    </Grid>
  );
};
