import React from "react";
import { Route, Routes } from "react-router-dom";

import { SimplifiedBranchCashReconciliationIndex } from "./SimplifiedBranchCashReconciliationIndex";
import { SimplifiedBranchCashReconciliationBranch } from "./branch/:branchId/SimplifiedBranchCashReconciliationBranch";
import { SimplifiedBranchCashReconciliationEdit } from "./:id/SimplifiedBranchCashReconciliationEdit";
import { SimplifiedBranchCashReconciliationHistory } from "./history/SimplifiedBranchCashReconciliationHistory";

export const SimplifiedBranchCashReconciliationRouter = () => {
  return (
    <Routes>
      <Route index element={<SimplifiedBranchCashReconciliationIndex />} />
      <Route
        path="/history"
        element={<SimplifiedBranchCashReconciliationHistory />}
      />
      <Route
        path="/branch/:branchId"
        element={<SimplifiedBranchCashReconciliationBranch />}
      />
      <Route path="/:id" element={<SimplifiedBranchCashReconciliationEdit />} />

      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      <Route path="*" element={<SimplifiedBranchCashReconciliationIndex />} /> */}
    </Routes>
  );
};
