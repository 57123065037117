import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import { CustomSelect } from "../inputs/CustomSelect";
import {
  getFirstUtcDateOfQuarter,
  getFirstUtcDateOfYear,
  getLastHalfMonthUtcDate,
  getOptionsFromValues,
  getDateRangeForMonth,
  getLastHalfOfTheMonthDate,
  getDateRangeForHalfMonth,
  getDateRangeForQuarter,
  getDateRangeForYear,
  getFirstHalfOfTheMonthDate,
} from "../../shared/helpers";
import dayjs, { Dayjs } from "dayjs";

import {
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type View = "half-month" | "month" | "year" | "quarter" | "all";

type Props = {
  handleSetStartDate: (date: dayjs.Dayjs) => void;
  handleSetEndDate: (date: dayjs.Dayjs) => void;
};

export const PeriodViewSelector = ({
  handleSetStartDate,
  handleSetEndDate,
}: Props) => {
  const [view, setView] = useState<View>("month");
  const [date, setDate] = useState<Dayjs>(dayjs());

  const handleSetDate = async (view: View, date: dayjs.Dayjs | null) => {
    switch (view) {
      case "half-month":
        setDate(getLastHalfMonthUtcDate(date));
        break;
      case "month":
        setDate(dayjs(date));
        break;
      case "quarter":
        setDate(getFirstUtcDateOfQuarter(date!));
        break;
      case "year":
        setDate(getFirstUtcDateOfYear(date));
        break;
    }
  };

  useEffect(() => {
    switch (view) {
      case "half-month": {
        const { startDate, endDate } = getDateRangeForHalfMonth(date, true);
        handleSetStartDate(dayjs(startDate));
        handleSetEndDate(dayjs(endDate));
        break;
      }
      case "month": {
        const { startDate, endDate } = getDateRangeForMonth(date, true);
        handleSetStartDate(dayjs(startDate));
        handleSetEndDate(dayjs(endDate));
        break;
      }
      case "quarter": {
        const { startDate, endDate } = getDateRangeForQuarter(date, true);
        handleSetStartDate(dayjs(startDate));
        handleSetEndDate(dayjs(endDate));
        break;
      }
      case "year": {
        const { startDate, endDate } = getDateRangeForYear(date, true);
        handleSetStartDate(dayjs(startDate));
        handleSetEndDate(dayjs(endDate));
        break;
      }
      case "all": {
        handleSetStartDate(dayjs("2000-01-01")); // Very old date
        handleSetEndDate(dayjs("2050-12-31")); // Far future date
        break;
      }
    }
  }, [date]);

  useEffect(() => {
    switch (view) {
      case "half-month": {
        setDate(getFirstHalfOfTheMonthDate(dayjs(date), true));
        break;
      }
      case "month": {
        setDate(dayjs(date));
        break;
      }
      case "quarter": {
        setDate(getFirstUtcDateOfQuarter(dayjs(date)));
        break;
      }
      case "year": {
        setDate(getFirstUtcDateOfYear(dayjs(date)));
        break;
      }
      case "all": {
        setDate(dayjs("2000-01-01"));
        break;
      }
    }
    // handleSetDate(view, date);
  }, [view]);

  const HalfWeekSelector = () => {
    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
      setDate(getLastHalfOfTheMonthDate(newValue!, true));
    };
    const handleDateChangeByNumber = (number: number) => {
      setDate(getLastHalfOfTheMonthDate(dayjs(date).add(number, "day"), true));
    };
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Button onClick={() => handleDateChangeByNumber(-15)}>
          <NavigateBeforeRounded />
        </Button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Half Week date"
            value={date}
            onChange={(newValue) => handleDateChange(newValue)}
            format="DD-MM-YYYY"
          />
          <Button onClick={() => handleDateChangeByNumber(14)}>
            <NavigateNextRounded />
          </Button>
        </LocalizationProvider>
      </Box>
    );
  };

  const MonthSelector = () => {
    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
      handleSetDate("month", newValue);
    };

    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          onClick={() => handleDateChange(dayjs(date).subtract(1, "month"))}
        >
          <NavigateBeforeRounded />
        </Button>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Month date"
            value={dayjs(date)}
            onChange={handleDateChange}
            format="MMM-YYYY"
          />
        </LocalizationProvider>
        <Button onClick={() => handleDateChange(dayjs(date).add(1, "month"))}>
          <NavigateNextRounded />
        </Button>
      </Box>
    );
  };

  const QuarterSelector = () => {
    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
      handleSetDate("quarter", newValue);
    };
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          onClick={() => handleDateChange(dayjs(date).subtract(3, "month"))}
        >
          <NavigateBeforeRounded />
        </Button>
        <DatePicker
          label="Quarter date"
          value={date}
          onChange={handleDateChange}
          format="MMM-YYYY"
        />
        <Button onClick={() => handleDateChange(dayjs(date).add(3, "month"))}>
          <NavigateNextRounded />
        </Button>
      </Box>
    );
  };

  const YearSelector = () => {
    const handleDateChange = (newValue: dayjs.Dayjs | null) => {
      handleSetDate("year", newValue);
    };
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <Button
          onClick={() => handleDateChange(dayjs(date).subtract(1, "year"))}
        >
          <NavigateBeforeRounded />
        </Button>
        <DatePicker
          label="Year date"
          value={date}
          onChange={handleDateChange}
          format="YYYY"
        />
        <Button onClick={() => handleDateChange(dayjs(date).add(1, "year"))}>
          <NavigateNextRounded />
        </Button>
      </Box>
    );
  };

  const AllSelector = () => {
    return <Box>AllSelector</Box>;
  };

  const renderOptions = () => {
    switch (view) {
      case "half-month":
        return <HalfWeekSelector />;
      case "month":
        return <MonthSelector />;
      case "quarter":
        return <QuarterSelector />;
      case "year":
        return <YearSelector />;
      case "all":
        return <AllSelector />;
    }
  };
  return (
    <>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        gap={2}
      >
        <Box flexGrow={1} width={{ xs: "100%", sm: "auto" }}>
          <CustomSelect
            label="View"
            options={getOptionsFromValues([
              "half-month",
              "month",
              "quarter",
              "year",
              "all",
            ])}
            value={view}
            onChange={(e) => setView(e.target.value as View)}
            fullWidth
          />
        </Box>
        <Box flexGrow={1} width={{ xs: "100%", sm: "auto" }}>
          {renderOptions()}
        </Box>
      </Box>

      <pre>{JSON.stringify({ date }, null, 2)}</pre>
    </>
  );
};
