import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import dayjsWithPlugins from "../../config/dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(isoWeek);
dayjs.extend(utc);

export const getWeekDatesByDate = (date: string | Date) => {
  console.log({ xxdate: date });
  // Parse the date as UTC and ensure the day starts at midnight in UTC
  const inputDate = dayjs.utc(date).startOf("day");

  // Get the start of the week (Monday, isoWeekday 1) in UTC
  const startOfWeek = inputDate.isoWeekday(1);

  // Generate an array with the dates of the entire week (from Monday to Sunday)
  const weekDates = Array.from({ length: 7 }, (_, index) =>
    startOfWeek.add(index, "day").format("YYYY-MM-DD")
  );

  return weekDates;
};

export const formatDate = (date?: string | Date) => {
  const dateObj = dayjs(date);

  console.log("formatDate", { date, dateObj });
  if (!date) return "N/A";
  return dayjs(date).format("DD-MMM-YYYY");
};

export const formatDateToDateDay = (date?: string | Date) => {
  if (!date) return "N/A";
  const dayObj = dayjs.utc(date); // Use UTC
  const dayAbbr =
    dayObj.format("ddd").charAt(0).toUpperCase() +
    dayObj.format("ddd").slice(1, 3); // Get capitalized day abbreviation (e.g., "Lun")
  const formattedDate = dayObj.format("DD/MMM"); // Get date in format "30/Sep"

  return `${dayAbbr}-${formattedDate}`;
};

export const isSaturdayOrSunday = (date: string): boolean => {
  const day = dayjs.utc(date).day();
  return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

export const isSunday = (date: string): boolean => {
  return dayjs.utc(date).day() === 0;
};

export const getMonday = (date?: Date | dayjs.Dayjs | string): Date => {
  let inputDate: dayjs.Dayjs;

  // Parse the input into a dayjs object in UTC
  if (dayjs.isDayjs(date)) {
    inputDate = date.utc();
  } else if (typeof date === "string" || date instanceof Date) {
    inputDate = dayjs.utc(date);
  } else {
    inputDate = dayjs.utc();
  }

  // Handle invalid date
  if (!inputDate.isValid()) {
    throw new Error("Invalid date provided");
  }

  // Adjust to the Monday of the current or previous week, setting time to 00:00:00 UTC
  const monday = inputDate.isoWeekday(1).startOf("day");

  // Return as a native Date object in UTC
  return monday.toDate();
};

export const getSunday = (date?: Date | dayjs.Dayjs | string): Date => {
  const monday = getMonday(date);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  return sunday;
};

export const dateIntoStringDate = (date: Date | string) => {
  return dayjsWithPlugins(date).utc().format("YYYY-MM-DD");
};

export const formatDateToAbbrDay = (dateString: string) => {
  // Parse the date in UTC to avoid local timezone issues
  const dayObj = dayjs.utc(dateString);

  // Get the first character of the abbreviated day of the week and the day of the month
  const dayAbbr = dayObj.format("ddd").charAt(0); // First character of day abbreviation
  const dayOfMonth = dayObj.format("DD"); // Day of the month

  return `${dayAbbr}${dayOfMonth}`;
};

export const getDatetimeFromTime = (time: string) => {
  const [hour, minute] = time.split(":").map(Number);
  return dayjs.utc().set("hour", hour).set("minute", minute);
};

export const getStringDateAsDDMMYYYY = (date: string) => {
  console.log({ date, result: dayjs.utc(date).format("DD-MM-YYYY") });
  return dayjs.utc(date).format("DD-MM-YYYY");
};

export const getFirstHalfMonthUtcDate = (
  date?: string | Date | dayjs.Dayjs
) => {
  // Use current date in UTC if date is undefined
  const dateObj = date ? dayjs.utc(date).local() : dayjs.utc().local();

  const monthDate = dateObj.date();

  // Return the 1st or the 16th depending on the day of the month in local time
  return monthDate < 16
    ? dayjs.utc(dateObj.year() + "-" + (dateObj.month() + 1) + "-01") // Set to the 1st of the month
    : dayjs.utc(dateObj.year() + "-" + (dateObj.month() + 1) + "-16"); // Set to the 16th of the month
};

export const getLastHalfMonthUtcDate = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  // Get the input date as local time
  const localDate = date ? dayjs(date) : dayjs();

  // Get the corresponding UTC date
  const utcDate = localDate.utc();

  // Get the current day in local time
  const dayOfMonth = localDate.date();
  const monthOfYear = localDate.month(); // 0-indexed (0 = January, 1 = February, etc.)

  // Debugging logs

  // Check if the local date is in the first half of the month (1-15)
  if (dayOfMonth >= 1 && dayOfMonth <= 15) {
    // Return the 15th of the current month in UTC
    console.log("Returning 15th of the month in UTC");
    return utcDate.set("month", monthOfYear).set("date", 15).startOf("day");
  } else {
    // If the day is 16 or greater, return the last day of the current month in UTC
    console.log("Returning last day of the current month in UTC");
    return utcDate.endOf("month").startOf("day");
  }
};

export const getFirstUtcDateOfMonth = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  // If no date is provided, use the current date
  const inputDate = date ? dayjs(date) : dayjs();

  // Create a UTC Day.js object for the first day of the month
  return dayjs.utc(`${inputDate.year()}-${inputDate.month() + 1}-01`);
};

export const getLastUtcDateOfMonth = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const localDate = date ? dayjs(date) : dayjs();

  const utcDate = localDate.utc();

  // Get the current day in local time
  const dayOfMonth = localDate.date();
  const monthOfYear = localDate.month();

  return utcDate
    .set("month", monthOfYear)
    .set("date", dayOfMonth)
    .endOf("month");
};

export const getFirstUtcDateOfYear = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  return dayjs.utc(`${inputDate.year()}-01-01`);
};

export const getLastUtcDateOfYear = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  return getFirstUtcDateOfYear(inputDate.add(1, "year"));
};

export const getFirstUtcDateOfQuarter = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  const month = inputDate.month();
  let quarterStartMonth;

  if (month < 3) quarterStartMonth = 0; // Jan, Feb, Mar
  else if (month < 6) quarterStartMonth = 3; // Apr, May, Jun
  else if (month < 9) quarterStartMonth = 6; // Jul, Aug, Sep
  else quarterStartMonth = 9; // Oct, Nov, Dec

  return dayjs.utc(`${inputDate.year()}-${quarterStartMonth + 1}-01`);
};

export const getLastUtcDateOfQuarter = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  const month = inputDate.month();

  let quarterEndMonth;
  if (month < 3) quarterEndMonth = 3;
  else if (month < 6) quarterEndMonth = 6;
  else if (month < 9) quarterEndMonth = 9;
  else quarterEndMonth = 12;
  return getFirstUtcDateOfQuarter(inputDate.add(quarterEndMonth, "month"));
};

export const formatDateToUTC = (date?: string | Date | dayjs.Dayjs) => {
  if (!date) return "N/A";
  return dayjs.utc(date).format("YYYY-MM-DD");
};

export const formatTimeToLocalTime = (time?: string) => {
  if (!time) return "N/A";

  return dayjs(time).format("HH:mm");
};

export const getCurrentUtcDate = () => {
  return dayjs().utc().startOf("day");
};

export const getCurrentUcdDate = () => {
  const currentDate = dayjs();
  const localDate = currentDate.format("YYYY-MM-DD");
  const newUtcDateWithLocalDate = dayjs.utc(`${localDate}T00:00:00Z`);

  const test = dayjs(localDate).utc().startOf("day");

  const startOfLocalDayInUTC = dayjs(localDate).startOf("day");
  const utcStartOfDay = dayjs(localDate).utc().startOf("day");

  console.log({
    currentDate: currentDate.toISOString(),
    localDate,
    newUtcDateWithLocalDate: newUtcDateWithLocalDate.toISOString(),

    test: test.toISOString(),
    startOfLocalDayInUTC: startOfLocalDayInUTC.toISOString(),
    utcStartOfDay: utcStartOfDay.toISOString(),
  });

  return newUtcDateWithLocalDate;
};

export const compareDates = (
  date1?: string | Date | Dayjs,
  date2?: string | Date | Dayjs
) => {
  if (!date1 || !date2) return false;
  let newDate1: dayjs.Dayjs = parseToDayjs(date1);
  let newDate2: dayjs.Dayjs = parseToDayjs(date2);

  // const newDate1 = dayjs(`${date1}T00:00:00Z`).utc();
  // const newDate2 = dayjs(`${date2}T00:00:00Z`).utc();

  const utcDate1 = dayjs(date1).format("YYYY-MM-DD");
  const utcDate2 = dayjs(date2).format("YYYY-MM-DD");

  console.log({ newDate1: newDate1.toISOString() });
  console.log({ newDate2: newDate2.toISOString() });
  console.log({
    date1: date1,
    date2: date2,
    newDate2,

    newDate1: newDate1.toISOString(),
    // newDate2S: newDate2.toISOString(),
    // newDate2: newDate2.toISOString(),
    utcDate1,
    utcDate2,
  });
  return newDate1.isSame(newDate2);
};

export const getUtcDateRangeForHalfMonth = (date: dayjs.Dayjs) => {
  const firstHalfMonthDate = getFirstHalfMonthUtcDate(date);
  const lastHalfMonthDate = getLastHalfMonthUtcDate(date);
  const mondayFirstHalfMonthDate = getMonday(firstHalfMonthDate.toISOString());
  const sundayLastHalfMonthDate = getSunday(lastHalfMonthDate.toISOString());

  return {
    startDate: mondayFirstHalfMonthDate,
    endDate: sundayLastHalfMonthDate,
  };
};

export const getUtcDateRangeForMonth = (date: dayjs.Dayjs) => {
  const firstMonthDate = getFirstUtcDateOfMonth(date);
  const lastMonthDate = getLastUtcDateOfMonth(date);
  const mondayFirstMonthDate = getMonday(firstMonthDate.toISOString());
  const sundayLastMonthDate = getSunday(lastMonthDate.toISOString());
  return {
    startDate: mondayFirstMonthDate,
    endDate: sundayLastMonthDate,
  };
};

export const getUtcDateRangeForQuarter = (date: dayjs.Dayjs) => {
  const firstQuarterDate = getFirstUtcDateOfQuarter(date);
  const lastQuarterDate = getLastUtcDateOfQuarter(date);
  const mondayFirstQuarterDate = getMonday(firstQuarterDate.toISOString());
  const sundayLastQuarterDate = getSunday(lastQuarterDate.toISOString());
  return {
    startDate: mondayFirstQuarterDate,
    endDate: sundayLastQuarterDate,
  };
};

export const getUtcDateRangeForYear = (date: dayjs.Dayjs) => {
  const firstYearDate = getFirstUtcDateOfYear(date);
  const lastYearDate = getLastUtcDateOfYear(date);
  const mondayFirstYearDate = getMonday(firstYearDate.toISOString());
  const sundayLastYearDate = getSunday(lastYearDate.toISOString());
  return {
    startDate: mondayFirstYearDate,
    endDate: sundayLastYearDate,
  };
};

// YYYY-MM-DD to UTC midnight
export const getUtcMidnightDayjs = (dateString: string | Date | Dayjs) => {
  const inputDayjs = dayjs(dateString);

  const newDate = dayjs(`${inputDayjs.format("YYYY-MM-DD")}T00:00:00Z`).utc();

  return newDate;
};

export const getMexicoCityTimeInUtc = (date: dayjs.Dayjs, time: string) => {
  // Convert the dayjs date to UTC and get the date portion directly as a string
  const utcDateString = date.utc().format("YYYY-MM-DD");

  // Combine the UTC date with the provided time
  const combinedDateTime = `${utcDateString}T${time}:00`;

  // Interpret the combined date-time as Mexico City time, then convert to UTC
  return dayjs.tz(combinedDateTime, "America/Mexico_City").utc();
};

export const getUtcStartOfDay = (date: string) => {
  return dayjs(`${date}T00:00:00Z`);
};

export const adjustUtcToLocalDate = (date: dayjs.Dayjs | Date) => {
  const offsetMinutes = dayjs().utcOffset();
  const offsetHours = offsetMinutes / 60;

  // Convert to Dayjs if it's a Date object
  const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);
  const adjustedDateTime = dayjsDate.subtract(offsetMinutes, "minute");

  return adjustedDateTime.startOf("day");
};

export const isDatetimeAfter = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime1).isAfter(dayjs(datetime2));
};

export const isDatetimeBefore = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime1).isBefore(dayjs(datetime2));
};

export const minutesBetweenDatetimes = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime2).diff(dayjs(datetime1), "minutes");
};

export const parseToDayjs = (dateInput: string | Date | Dayjs): Dayjs => {
  if (dayjs.isDayjs(dateInput)) {
    console.log("dateInput is already a dayjs object", dateInput);
    return dateInput; // Already a dayjs object
  } else if (dateInput instanceof Date) {
    console.log("dateInput is a Date object", dateInput);
    return dayjs(dateInput); // Convert Date object to dayjs
  } else if (typeof dateInput === "string") {
    if (!isNaN(Date.parse(dateInput))) {
      console.log("dateInput is an ISO string", dateInput);
      return dayjs(dateInput); // Parse ISO string as-is
    } else {
      console.log("dateInput is a non-ISO string", dateInput);
      return dayjs(`${dateInput}T00:00:00Z`).utc(); // Parse non-ISO string as UTC midnight
    }
  } else {
    throw new Error("Invalid date input type");
  }
};

export const formatDatetime = (datetime?: string | Date | Dayjs) => {
  if (!datetime) return "N/A";
  return dayjs(datetime).format("YYYY-MM-DD HH:mm");
};

// local or mexican dates
export const getFirstDayOfMonth = (
  date: Dayjs,
  useMexicoTime: boolean = false
) => {
  const currentTimeZone = dayjs.tz.guess(); // Guess user's current time zone
  const mexicoTimeZone = "America/Mexico_City";
  const localTimeZone = currentTimeZone;
  const timeZoneToUse = useMexicoTime ? mexicoTimeZone : localTimeZone;
  return dayjs.tz(date, timeZoneToUse).startOf("month");
};

export const getLastDayOfMonth = (
  date: Dayjs,
  useMexicoTime: boolean = false
) => {
  const currentTimeZone = dayjs.tz.guess(); // Guess user's current time zone
  const mexicoTimeZone = "America/Mexico_City";
  const localTimeZone = currentTimeZone;
  const timeZoneToUse = useMexicoTime ? mexicoTimeZone : localTimeZone;
  return dayjs.tz(date, timeZoneToUse).endOf("month");
};

export const getDateRangeForHalfMonth = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstHalfMonthDate = getFirstHalfOfTheMonthDate(date, useMexicoTime);
  const lastHalfMonthDate = getLastHalfOfTheMonthDate(date, useMexicoTime);

  return {
    startDate: firstHalfMonthDate,
    endDate: lastHalfMonthDate,
  };
};

export const getDateRangeForMonth = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstMonthDate = getFirstDayOfMonth(date, useMexicoTime);
  const lastMonthDate = getLastDayOfMonth(date, useMexicoTime);

  return {
    startDate: firstMonthDate,
    endDate: lastMonthDate,
  };
};

export const getDateRangeForQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstQuarterDate = getFirstDateOfQuarter(date, useMexicoTime);
  const lastQuarterDate = getLastDateOfQuarter(date, useMexicoTime);

  return {
    startDate: firstQuarterDate,
    endDate: lastQuarterDate,
  };
};

export const getDateRangeForYear = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const firstYearDate = dayjs.tz(date, timeZoneToUse).startOf("year");
  const lastYearDate = dayjs.tz(date, timeZoneToUse).endOf("year");

  return {
    startDate: firstYearDate,
    endDate: lastYearDate,
  };
};

export const getFirstHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);
  return adjustedDate.date() < 16
    ? adjustedDate.startOf("month")
    : adjustedDate.startOf("month").add(15, "days");
};

export const getLastHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);

  if (adjustedDate.date() < 16) {
    return adjustedDate.startOf("month").add(14, "days").endOf("day");
  } else {
    return adjustedDate.endOf("month");
  }
};

export const getFirstDateOfQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);
  const quarterStartMonth = Math.floor(adjustedDate.month() / 3) * 3; // Calculate the first month of the quarter

  return adjustedDate.month(quarterStartMonth).startOf("month");
};

export const getLastDateOfQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstQuarterDate = getFirstDateOfQuarter(date, useMexicoTime);
  return firstQuarterDate.add(3, "months").subtract(1, "day").endOf("day");
};

export const cconvertUtcDateToMexicoTimeStartOfDay = (
  utcDate: Date | string
) => {
  // Convert UTC date to Mexico City timezone
  const dateString = dayjs.utc(utcDate).format("YYYY-MM-DD");
  // Create Mexico time at start of the same date
  return dayjs.tz(`${dateString}T00:00:00`, "America/Mexico_City");
};

export const getFirstMondayOfExtendedHalfWeekMx = (date: dayjs.Dayjs) => {
  const dayOfMonth = date.date();
  const targetDay = dayOfMonth <= 15 ? 1 : 16;
  const targetDate = date.date(targetDay);

  const dayOfWeek = targetDate.day();

  if (dayOfWeek === 1) {
    return targetDate;
  }

  const daysToMonday = (dayOfWeek + 6) % 7;
  const monday = targetDate.subtract(daysToMonday, "day");

  return monday;
};

export const getLastSundayOfExtendedHalfWeekMx = (date: dayjs.Dayjs) => {
  const dayOfMonth = date.date();

  const targetDay = dayOfMonth <= 15 ? 15 : date.daysInMonth();
  const targetDate = date.date(targetDay);

  const dayOfWeek = targetDate.day();
  if (dayOfWeek === 0) {
    return targetDate;
  }

  const daysToSunday = (7 - dayOfWeek) % 7; // Days to add to reach Sunday
  const sunday = targetDate.add(daysToSunday, "day");

  return sunday;
};
