import React, { useEffect, useState } from "react";
import "react-day-picker/dist/style.css";
import { useAppSelector } from "../../../../hooks/useAppSelector";

import { TimeOffType } from "../../../../shared/types/time-off-request.types";
import { RemainingVacationDays, TimeOffRequestForm } from "../components";
import { useAppDispatch } from "../../../../hooks";
import { TimeOffRequestThunks } from "../../../../store/thunks";
import { TimeOffRequestService } from "../../../../services";

export const TimeOffRequestFormWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const service = new TimeOffRequestService();
  const [remainingVacationDays, setRemainingVacationDays] = useState(0);
  const [selectedDates, setselectedDates] = useState<Date[]>([]);
  const [currentMonth, setcurrentMonth] = useState<Date>(new Date());
  const [timeOffType, setTimeOffType] = useState<TimeOffType>(
    TimeOffType.Vacation
  );

  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const { isLoading, activeCollaboratorTimeOffOverview } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const handleMonthChange = (newMonth: Date) => {
    setcurrentMonth(newMonth);
  };

  const handleSelectedDatesChange = (dates: Date[]) => {
    setselectedDates(dates);
  };

  useEffect(() => {
    dispatch(
      TimeOffRequestThunks.getCollaboratorTimeOffOverview({
        id: uid!,
        params: {
          endDate: currentMonth.toISOString(),
        },
      })
    );
    return () => {};
  }, [currentMonth]);

  useEffect(() => {
    if (!activeCollaboratorTimeOffOverview) return;

    setRemainingVacationDays(
      activeCollaboratorTimeOffOverview.remainingVacationDays
    );
  }, [activeCollaboratorTimeOffOverview]);

  useEffect(() => {
    if (!activeCollaboratorTimeOffOverview) return;
    if (service.isTimeOffTypeVacation(timeOffType)) {
      setRemainingVacationDays(
        activeCollaboratorTimeOffOverview.remainingVacationDays -
          selectedDates.length
      );
    } else {
      setRemainingVacationDays(
        activeCollaboratorTimeOffOverview.remainingVacationDays
      );
    }
  }, [timeOffType, selectedDates, activeCollaboratorTimeOffOverview]);

  return (
    <>
      <RemainingVacationDays
        isLoading={isLoading}
        remainingVacationDays={remainingVacationDays}
      />
      <TimeOffRequestForm
        handleMonthChange={handleMonthChange}
        handleSelectedDatesChange={handleSelectedDatesChange}
        setTimeOffType={setTimeOffType}
      />
    </>
  );
};
