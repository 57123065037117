import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  Paper,
  Box,
  TableRow,
  TableCell,
  Button,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  TimeOffRequestResponse,
  TimeOffStatus,
} from "../../../../shared/types/time-off-request.types";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import { getCollaboratorById } from "../../../../helpers/collaboratorsHelpers";
import { useNavigate } from "react-router-dom";

type TimeOffRequestsListProps = { timeOffRequests: TimeOffRequestResponse[] };
export const TimeOffRequestsList = ({
  timeOffRequests,
}: TimeOffRequestsListProps) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const navigate = useNavigate();

  // Separate pending and approved/rejected requests
  const pendingRequests = timeOffRequests
    .filter((timeOffRequest) => timeOffRequest.status === TimeOffStatus.Pending)
    .sort((a, b) => Number(a.requestedAt) - Number(b.requestedAt));

  const resolvedRequests = timeOffRequests
    .filter(
      (timeOffRequest) =>
        timeOffRequest.status === TimeOffStatus.Approved ||
        timeOffRequest.status === TimeOffStatus.Rejected
    )
    .sort((a, b) => Number(b.requestedAt) - Number(a.requestedAt));

  const canceledRequests = timeOffRequests.filter(
    (timeOffRequest) => timeOffRequest.status === TimeOffStatus.Canceled
  );

  const handleClick = (id: string) => {
    navigate(`/dashboard/time-off-requests/${id}`);
  };

  const renderRow = (request: TimeOffRequestResponse) => {
    return (
      <TableRow
        key={request.id}
        style={{
          backgroundColor:
            request.status === TimeOffStatus.Approved
              ? "lightgreen"
              : request.status === TimeOffStatus.Rejected
              ? "lightcoral"
              : request.status === TimeOffStatus.Canceled
              ? "lightgray"
              : "white",
        }}
      >
        <TableCell>
          <CustomAvatar
            collaborator={
              getCollaboratorById(collaborators, request.collaborator!)!
            }
          />
        </TableCell>
        <TableCell>
          {new Date(request.requestedAt!).toLocaleDateString()}
        </TableCell>
        <TableCell>{request.requestedDays.length}</TableCell>
        <TableCell>
          {request.requestedDays
            .map((date) => new Date(date).toLocaleDateString())
            .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
            .join(", ")}
        </TableCell>
        <TableCell>{request.timeOffType}</TableCell>
        <TableCell>{request.status}</TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClick(request.id)}
          >
            <VisibilityIcon />
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {pendingRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {resolvedRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} style={{ marginBottom: "20px" }}>
        <Table>
          <TableBody>
            {canceledRequests.map((request) => renderRow(request))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
