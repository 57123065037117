import { IBaseEntity, IBaseResponseEntity } from "./base.entity";

export interface IAttendanceRecordBase extends IBaseEntity {
  shiftDate: string;
  startTime?: string;
  endTime?: string;
  clockInBranch: string;
  clockOutBranch?: string;
  collaborator: string;
  startLatitude?: number;
  startLongitude?: number;
  endLatitude?: number;
  endLongitude?: number;
  action?: AttendanceRecordAction;
}

export interface IAttendanceRecord
  extends Omit<
      IAttendanceRecordBase,
      "id" | "createdAt" | "createdBy" | "updatedAt" | "updatedBy"
    >,
    IBaseResponseEntity {}

export enum AttendanceRecordAction {
  ClockIn = "clock_in",
  ClockOut = "clock_out",
}

export interface AttendanceReport {
  date: Date;
  shiftStartingTime: Date | null;
  shiftEndingTime: Date | null;
  attendanceStartingTime: Date | null;
  attendanceEndingTime: Date | null;
  workedTime: number;
  assistanceRecord: boolean;
  minutesDelay: number;
  extraHours: number;
  status: AttendanceReportStatus;
}

export type AttendanceReportStatus =
  | "RETARDO"
  | "SALIDA ANTICIPADA"
  | "DESCANSO"
  | "NO CALENDARIZADA"
  | "INCOMPLETA"
  | "INJUSTIFICADA"
  | "ASISTENCIA"
  | "ERROR IMPREVISTO"
  | "FUTURO";

// new schema
export interface IAttendanceRecordBaseNew extends IBaseEntity {
  shiftDate: string | Date;
  startTime?: string | Date;
  endTime?: string | Date;
  clockInBranch: string;
  clockOutBranch?: string;
  collaborator: string;
  startLatitude?: number;
  startLongitude?: number;
  endLatitude?: number;
  endLongitude?: number;
  action?: AttendanceRecordAction;
}
export interface IAttendanceRecordRequest extends IAttendanceRecordBaseNew {
  shiftDate: string;
  startTime?: string;
  endTime?: string;
}

export interface IAttendanceRecordResponse extends IAttendanceRecordBaseNew {
  id: string;
  shiftDate: string;
  startTime?: string;
  endTime?: string;
}

export interface IAttendanceRecordEntity extends IAttendanceRecordBaseNew {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  shiftDate: Date;
  startTime?: Date;
  endTime?: Date;
}
