import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { TimeOffRequestsList } from "../components";

import { useTimeOffRequests } from "../../../../hooks";
import { TimeOffRequestResponse } from "../../../../shared/types";

export const TimeOffRequestsCollaborator = () => {
  const { fetchCurrentCollaboratorTimeOffRequests } = useTimeOffRequests();

  const [timeOffRequests, setTimeOffRequests] = useState<
    TimeOffRequestResponse[]
  >([]);

  useEffect(() => {
    const fetchTimeOffRequests = async () => {
      const timeOffRequests = await fetchCurrentCollaboratorTimeOffRequests();
      setTimeOffRequests(timeOffRequests);
    };
    fetchTimeOffRequests();
  }, []);

  const { uid } = useAppSelector((state) => state.authReducer.user!);

  const collaboratorTimeOffRequests = timeOffRequests.filter(
    (timeOffRequest) => timeOffRequest.collaborator === uid
  );
  return (
    <>
      <TimeOffRequestsList timeOffRequests={collaboratorTimeOffRequests} />
    </>
  );
};
