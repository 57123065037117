export const BRANCH_COORDINATES = [
  // Urban
  { lat: 21.0198725, lng: -89.5961368 },
  // Montejo
  { lat: 21.0036815, lng: -89.6147023 },
  // Harbor
  { lat: 21.046548, lng: -89.630642 },
];

export const URBAN_COORDS = { lat: 21.0198725, lng: -89.5961368 };
export const MONTEJO_COORDS = { lat: 21.0036815, lng: -89.6147023 };
export const HARBOR_COORDS = { lat: 21.046548, lng: -89.630642 };

export const BRANCHES_IDS = {
  URBAN_ID: "670a61538ff5ac02957e50f3",
  MONTEJO_ID: "670a61538ff5ac02957e50e3",
  HARBOR_ID: "670a61538ff5ac02957e50eb",
};
