import {
  CollaboratorDayShift,
  IAttendanceRecord,
  AttendanceReport,
  AttendanceReportStatus,
} from "../types";
import {
  getUtcStartOfDay,
  getUtcMidnightDayjs,
  adjustUtcToLocalDate,
  minutesBetweenDatetimes,
} from "./date.helpers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const calculateMinutesBetweenDates = (
  date1?: Date | null,
  date2?: Date | null
): number => {
  if (!date1 || !date2) return 0;
  return Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60));
};

// creeate a safe for this is possible that startdate is greater than enddate

const generateDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  if (startDate >= endDate) {
    [startDate, endDate] = [endDate, startDate];
  }
  const dates: Date[] = [];
  let current = getUtcMidnightDayjs(startDate);
  const end = getUtcMidnightDayjs(endDate);

  while (current.isBefore(end) || current.isSame(end, "day")) {
    dates.push(current.toDate());
    current = current.add(1, "day"); // Fixed: reassign the result
  }

  return dates;
};

export const convertShiftMexicoTimeToUtc = (shift: CollaboratorDayShift) => {
  return {
    ...shift,
    shiftDate: getUtcStartOfDay(shift.shiftDate),
  };
};

export const getShiftTimes = (shift: CollaboratorDayShift) => {
  console.log("SHIFT", shift);
  const { shiftDate, startingTime, endingTime } = shift;
  // Combine the local date with the specified times and convert to UTC
  const startTime = startingTime
    ? dayjs
        .tz(
          `${shiftDate.substring(0, 10)} ${startingTime}`,
          "YYYY-MM-DD HH:mm",
          "America/Mexico_City"
        )
        .utc()
    : null;

  const endTime = endingTime
    ? dayjs
        .tz(
          `${shiftDate.substring(0, 10)} ${endingTime}`,
          "YYYY-MM-DD HH:mm",
          "America/Mexico_City"
        )
        .utc()
    : null;

  return { startTime, endTime };
};

const normalizeShiftDates = (collaboratorDayShifts: CollaboratorDayShift[]) => {
  return collaboratorDayShifts.map((shift) => {
    const { startTime, endTime } = getShiftTimes(shift);
    return {
      ...shift,
      shiftDate: getUtcStartOfDay(shift.shiftDate),
      startingTime: startTime,
      endingTime: endTime,
    };
  });
};

const calculateWorkedTime = (attendance: IAttendanceRecord): number => {
  if (!attendance.startTime || !attendance.endTime) return 0;
  return calculateMinutesBetweenDates(
    new Date(attendance.startTime),
    new Date(attendance.endTime)
  );
};

const getStatus = (
  attendanceReport: AttendanceReport
): AttendanceReportStatus => {
  if (attendanceReport.shiftStartingTime && attendanceReport.shiftEndingTime) {
    if (dayjs(attendanceReport.shiftEndingTime).isAfter(dayjs()))
      return "FUTURO";
    if (
      !attendanceReport.attendanceStartingTime &&
      !attendanceReport.attendanceEndingTime
    ) {
      return "INJUSTIFICADA";
    }

    if (
      !attendanceReport.attendanceStartingTime ||
      !attendanceReport.attendanceEndingTime
    )
      return "INCOMPLETA";
    if (
      minutesBetweenDatetimes(
        attendanceReport.shiftStartingTime,
        attendanceReport.attendanceStartingTime
      ) > 10
    )
      return "RETARDO";

    if (
      minutesBetweenDatetimes(
        attendanceReport.shiftEndingTime,
        attendanceReport.attendanceEndingTime
      ) < -10
    )
      return "SALIDA ANTICIPADA";

    return "ASISTENCIA";
  }

  if (
    !attendanceReport.shiftStartingTime &&
    !attendanceReport.shiftEndingTime
  ) {
    if (
      attendanceReport.attendanceStartingTime &&
      attendanceReport.attendanceEndingTime
    ) {
      return "NO CALENDARIZADA";
    }
    if (
      !attendanceReport.attendanceStartingTime &&
      !attendanceReport.attendanceEndingTime
    )
      return "DESCANSO";
  }

  return "ERROR IMPREVISTO";
};

export const getAttendanceRecordsReport = (
  attendanceRecords: IAttendanceRecord[],
  collaboratorsDayShifts: CollaboratorDayShift[],
  collaboratorId: string,
  startDate: Date,
  endDate: Date
): AttendanceReport[] => {
  const allDates = generateDatesInRange(startDate, endDate);
  const normalizedShifts = normalizeShiftDates(collaboratorsDayShifts);

  console.log({
    allDates: allDates.map((date) => date.toISOString()),
  });
  console.log({
    attendanceRecords: attendanceRecords.map((r) => r.shiftDate),
  });

  return allDates.map((date) => {
    const attendanceReport: AttendanceReport = {
      date: adjustUtcToLocalDate(date).toDate(),
      shiftStartingTime: null,
      shiftEndingTime: null,
      attendanceStartingTime: null,
      attendanceEndingTime: null,
      workedTime: 0,
      assistanceRecord: false,
      minutesDelay: 0,
      extraHours: 0,
      status: "ERROR IMPREVISTO",
    };

    const shift = normalizedShifts.find(
      (s) =>
        dayjs(s.shiftDate).isSame(date, "day") &&
        s.collaboratorId === collaboratorId
    );

    const attendance = attendanceRecords.find(
      (r) =>
        dayjs(r.shiftDate).isSame(date, "day") &&
        r.collaborator === collaboratorId
    );

    if (shift) {
      attendanceReport.shiftStartingTime =
        shift?.startingTime?.toDate() ?? null;
      attendanceReport.shiftEndingTime = shift?.endingTime?.toDate() ?? null;
    }

    if (attendance) {
      attendanceReport.attendanceStartingTime = attendance.startTime
        ? new Date(attendance.startTime)
        : null;
      attendanceReport.attendanceEndingTime = attendance.endTime
        ? new Date(attendance.endTime)
        : null;
      attendanceReport.workedTime = calculateWorkedTime(attendance);
      attendanceReport.assistanceRecord = true;
    }

    if (shift && attendance) {
      if (
        attendanceReport.shiftStartingTime &&
        attendanceReport.attendanceStartingTime
      ) {
        attendanceReport.minutesDelay = calculateMinutesBetweenDates(
          attendanceReport.shiftStartingTime,
          attendanceReport.attendanceStartingTime
        );
        const expectedWorkMinutes = calculateMinutesBetweenDates(
          attendanceReport.shiftStartingTime,
          attendanceReport.shiftEndingTime
        );
        attendanceReport.extraHours =
          attendanceReport.workedTime > expectedWorkMinutes
            ? (attendanceReport.workedTime - expectedWorkMinutes) / 60
            : 0;
      }
    }

    const status = getStatus(attendanceReport);
    attendanceReport.status = status;

    return attendanceReport;
  });
};
