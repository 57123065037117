import React, { useEffect, useState } from "react";
import { PeriodViewSelector } from "../../../../components/misc/PeriodViewSelector";
import { Dayjs } from "dayjs";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { SimplifiedBranchCashReconciliationService } from "../../../../services";
import { SimplifiedBranchCashReconciliationTable } from "../components";
import { useAuthHook } from "../../../../hooks";
import { SimplifiedBranchCashReconciliationTotals } from "../components/SimplifiedBranchCashReconciliationTotals";

export const SimplifiedBranchCashReconciliationHistory = () => {
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [
    simplifiedBranchCashReconciliations,
    setSimplifiedBranchCashReconciliations,
  ] = useState<ISimplifiedBranchCashReconciliation[]>();

  const { isAdmin } = useAuthHook();

  useEffect(() => {
    const fetchSimplifiedBranchCashReconciliations = async () => {
      if (startDate && endDate) {
        const service = new SimplifiedBranchCashReconciliationService();
        const data = await service.getAll({
          $transactionDatetime: `$range:${startDate.toISOString()}...${endDate.toISOString()}`,
        });
        const simplifiedBranchCashReconciliations = data.data.map((item) =>
          service.transformResponseToEntity(item)
        );
        const sorted = simplifiedBranchCashReconciliations.sort(
          (a, b) =>
            new Date(b.transactionDatetime).getTime() -
            new Date(a.transactionDatetime).getTime()
        );
        setSimplifiedBranchCashReconciliations(sorted);
      }
    };

    fetchSimplifiedBranchCashReconciliations();
  }, [startDate, endDate]);

  return (
    <>
      <PeriodViewSelector
        handleSetStartDate={setStartDate}
        handleSetEndDate={setEndDate}
      />
      <SimplifiedBranchCashReconciliationTotals
        reconciliations={simplifiedBranchCashReconciliations || []}
      />
      {simplifiedBranchCashReconciliations &&
      simplifiedBranchCashReconciliations.length > 0 ? (
        <SimplifiedBranchCashReconciliationTable
          reconciliations={simplifiedBranchCashReconciliations}
          linkTo="reconciliation"
        />
      ) : (
        <div>No data found</div>
      )}
      {isAdmin && (
        <>
          <pre>{JSON.stringify(startDate, null, 2)}</pre>
          <pre>{JSON.stringify(endDate, null, 2)}</pre>
          <pre>
            {JSON.stringify(simplifiedBranchCashReconciliations, null, 2)}
          </pre>
        </>
      )}
    </>
  );
};
