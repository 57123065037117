import Swal from "sweetalert2";
import { useCallback, useState } from "react";
import { useAppDispatch } from "./useAppDispatch";
import { useAppSelector } from "./useAppSelector";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";
import {
  CollaboratorTimeOffOverview,
  TimeOffRequest,
  TimeOffRequestRequest,
  TimeOffRequestResponse,
  TimeOffStatus,
} from "../shared/types/time-off-request.types";
import { ApiResponse } from "../shared/types/responses";
import {
  setTimeOffRequests,
  setLoading,
  setCollaboratorsTimeOffOverview,
  setActiveCollaboratorTimeOffOverview,
  setIsAllLoading,
} from "../store/slice/timeOffRequestSlice";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { TimeOffRequestService } from "../services";
import { TimeOffRequestThunks } from "../store/thunks";
import { fireSwalError, fireSwalSuccess } from "../helpers";
import { WebAppRole } from "../shared/types";

// todo: a lot of this functions are not required anymore
export const useTimeOffRequests = () => {
  const dispatch = useAppDispatch();
  const service = new TimeOffRequestService();

  const { uid, role } = useAppSelector((state) => state.authReducer.user!);

  const navigate = useNavigate();

  const [timeOffRequest, setTimeOffRequest] =
    useState<TimeOffRequestResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTimeOffRequest = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const response = await service.getById(id);
        console.log({ response });
        setTimeOffRequest(response.data);
      } catch (error) {
        setError("Error fetching time off request");
      } finally {
        setIsLoading(false);
      }
    },
    [service]
  );

  const fetchCurrentCollaboratorTimeOffRequests = useCallback(async () => {
    const response = await service.getAll({
      collaborator: uid,
    });
    return response.data;
  }, [uid, service]);

  const updateTimeOffRequest = useCallback(
    async (timeOffRequest: TimeOffRequestResponse) => {
      setIsLoading(true);
      try {
        const response = await service.update(
          timeOffRequest.id,
          timeOffRequest
        );
        fireSwalSuccess("Time off request updated successfully");
        setTimeOffRequest(response.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          fireSwalError(
            error.response?.data?.message || "Error updating time off request"
          );
        } else {
          fireSwalError("Error updating time off request");
        }
      } finally {
        setIsLoading(false);
      }
    },
    [service]
  );

  const createTimeOffRequest = useCallback(
    async (timeOffRequest: TimeOffRequestRequest) => {
      try {
        const response = await service.create(timeOffRequest);
        fireSwalSuccess("Time off request created successfully");
        if (role === WebAppRole.manager || role === WebAppRole.admin) {
          navigate("/dashboard/time-off-requests/approval");
        } else {
          navigate("/dashboard/time-off-requests/requests-status");
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          fireSwalError(
            error.response?.data?.message || "Error creating time off request"
          );
        }
      }
    },
    [service]
  );

  const deleteTimeOffRequest = useCallback(
    async (id: string) => {
      try {
        const response = await service.delete(id);

        response.data;
        await fireSwalSuccess("Time off request deleted successfully");
        if (role === WebAppRole.manager || role === WebAppRole.admin) {
          navigate("/dashboard/time-off-requests/approval");
        } else {
          navigate("/dashboard/time-off-requests/");
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          fireSwalError(
            error.response?.data?.message || "Error deleting time off request"
          );
        } else {
          fireSwalError("Error deleting time off request");
        }
      }
    },
    [service]
  );

  const storeAllTimeOffRequests = useCallback(async () => {
    dispatch(TimeOffRequestThunks.fetchItems({}));
  }, [service, dispatch]);

  const startLoadingTimeOffRequests = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<TimeOffRequest[]>
      >("/time-off-requests");
      const fetchedTimeOffRequests = data.data;

      dispatch(setTimeOffRequests(fetchedTimeOffRequests));

      // TODO: Continue
    } catch (error: any) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const startLoadingCollaboratorsTimeOffOverview = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const { data } = await hvpAuthenticatedApi.get<
        ApiResponse<CollaboratorTimeOffOverview[]>
      >("/time-off-requests/collaborators/time-off-overview");

      const fethedData = data.data;
      dispatch(setCollaboratorsTimeOffOverview(fethedData));
    } catch (error) {
      console.error("Axios Error:", error);
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]); // include any dependencies here

  const startLoadingCollaboratorTimeOffOverview = useCallback(
    async (endDate?: Date) => {
      dispatch(setLoading(true));
      try {
        const { data } = await hvpAuthenticatedApi.get<
          ApiResponse<CollaboratorTimeOffOverview>
        >(`/time-off-requests/collaborators/time-off-overview/${uid}`, {
          params: {
            endDate: endDate?.toISOString(), // Convert Date to ISO string if endDate is provided
          },
        });

        const fethedData = data.data;
        dispatch(setActiveCollaboratorTimeOffOverview(fethedData));
      } catch (error) {
        console.error("Axios Error:", error);
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, uid]
  );

  const startLoadingInitialState = useCallback(async () => {
    dispatch(setIsAllLoading(true));
    try {
      await Promise.all([
        startLoadingTimeOffRequests(),
        startLoadingCollaboratorsTimeOffOverview(),
        startLoadingCollaboratorTimeOffOverview(),
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      dispatch(setIsAllLoading(false));
    }
  }, [
    dispatch,
    startLoadingTimeOffRequests,
    startLoadingCollaboratorsTimeOffOverview,
    startLoadingCollaboratorTimeOffOverview,
  ]);

  const startSavingTimeOffRequest = useCallback(
    async (timeOffRequest: TimeOffRequest) => {
      dispatch(setLoading(true));
      try {
        const object = { ...timeOffRequest, collaborator: uid };

        const response = await hvpAuthenticatedApi.post<
          ApiResponse<TimeOffRequest>
        >("/time-off-requests", object);
        if (response.data.ok) {
          Swal.fire({
            icon: "success",
            title: "Registro exitoso",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        await startLoadingInitialState();
        navigate("/dashboard/time-off-requests/requests-status"); // Updated path
      } catch (error: unknown) {
        let errorMessage =
          (error as Error)?.message || "Error processing the request.";
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.detail || errorMessage;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, uid, startLoadingInitialState, navigate]
  );

  const startApprovingTimeOffRequest = useCallback(
    async (id: string, managerNote: string, timeOffStatus: TimeOffStatus) => {
      dispatch(setLoading(true));

      try {
        const body: Partial<TimeOffRequest> = {
          managerNote,
          status: timeOffStatus,
          approvedBy: uid,
        };

        console.log({ body });

        const response = await hvpAuthenticatedApi.patch<
          ApiResponse<TimeOffRequest>
        >(`/time-off-requests/${id}/approve`, body);
        if (response.data.ok) {
          Swal.fire({
            icon: "success",
            title: "Registro exitoso",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        await startLoadingInitialState();
        navigate("/dashboard/time-off-requests/approval"); // Updated path
      } catch (error: unknown) {
        let errorMessage =
          (error as Error)?.message || "Error processing the request.";
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.detail || errorMessage;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, uid, startLoadingInitialState, navigate]
  );

  const startUpdatingTimeOffRequest = useCallback(
    async (timeOffRequest: TimeOffRequest) => {
      dispatch(setLoading(true));
      try {
        const response = await hvpAuthenticatedApi.put<
          ApiResponse<TimeOffRequest>
        >(`/time-off-requests/${timeOffRequest._id}`, timeOffRequest);
        if (response.data.ok) {
          Swal.fire({
            icon: "success",
            title: "Registro actualizado exitosamente",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        await startLoadingInitialState();
        navigate("/dashboard/time-off-requests");
      } catch (error: unknown) {
        let errorMessage =
          (error as Error)?.message || "Error processing the request.";
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.detail || errorMessage;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, startLoadingInitialState, navigate]
  );

  const startDeletingTimeOffRequest = useCallback(
    async (id: string) => {
      dispatch(setLoading(true));
      try {
        const response = await hvpAuthenticatedApi.delete<
          ApiResponse<TimeOffRequest>
        >(`/time-off-requests/${id}`);
        if (response.data.ok) {
          Swal.fire({
            icon: "success",
            title: "Registro eliminado exitosamente",
            showConfirmButton: false,
            timer: 1000,
          });
        }
        await startLoadingInitialState();
        navigate("/dashboard/time-off-requests/approval"); // Updated path
      } catch (error: unknown) {
        let errorMessage =
          (error as Error)?.message || "Error processing the request.";
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.detail || errorMessage;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, startLoadingInitialState, navigate]
  );

  return {
    startLoadingTimeOffRequests,
    startLoadingCollaboratorsTimeOffOverview,
    startLoadingCollaboratorTimeOffOverview,
    startLoadingInitialState,
    startSavingTimeOffRequest,
    startApprovingTimeOffRequest,
    startDeletingTimeOffRequest,
    startUpdatingTimeOffRequest,
    storeAllTimeOffRequests,
    fetchCurrentCollaboratorTimeOffRequests,
    fetchTimeOffRequest,
    timeOffRequest,
    isLoading,
    error,
    updateTimeOffRequest,
    deleteTimeOffRequest,
    createTimeOffRequest,
  };
};
