import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@mui/material";
import { YearSelector } from "../../../../components";
import { publicHolidaysThunks } from "../../../../store/thunks";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import { IPublicHolidaysResponse } from "../../../../shared/types";
import { convertUtcDateToMexicoTimeStartOfDay } from "../../../../shared/helpers";
import { formatDateToLongSpanish } from "../../../../shared/helpers";

export const PublicHolidaysIndex = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentPublicHolidays, setCurrentPublicHolidays] =
    useState<IPublicHolidaysResponse>();
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { publicHolidays } = useAppSelector((state) => state.publicHolidays);

  useEffect(() => {
    dispatch(publicHolidaysThunks.fetchItems());
  }, [dispatch]);

  useEffect(() => {
    const yearPublicHolidays = publicHolidays.find(
      (item) => item.year === year
    );

    setCurrentPublicHolidays(yearPublicHolidays);
  }, [publicHolidays, year]);

  const handleEditClick = () => {
    navigate(
      `/dashboard/parameters/public-holidays/edit/${currentPublicHolidays?.id}`
    );

    console.log("Edit button clicked!");
  };

  const renderPublicHolidays = () => {
    return currentPublicHolidays?.publicHolidays.map((holiday, index) => (
      <ListItem key={index}>
        <ListItemText
          primary={formatDateToLongSpanish(
            convertUtcDateToMexicoTimeStartOfDay(holiday)
          )}
        />
      </ListItem>
    ));
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight="bold" mr={2}>
            Días Festivos de {year}
          </Typography>
          <YearSelector year={year} setYear={setYear} />
        </Box>

        <Button variant="outlined" color="primary" onClick={handleEditClick}>
          Editar
        </Button>
      </Box>

      <Divider />

      <Box mt={3}>
        {currentPublicHolidays ? (
          <List>{renderPublicHolidays()}</List>
        ) : (
          <Typography color="textSecondary" align="center">
            No se encontraron días festivos para este año.
          </Typography>
        )}
      </Box>
    </Box>
  );
};
