import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  TimeOffRequestCreate,
  TimeOffRequestDetailView,
  TimeOffRequestFormWrapper,
  TimeOffRequestIndex,
  TimeOffRequestsAll,
  TimeOffRequestsCollaborator,
  TimeOffRequestsCollaboratorInfo,
  TimeOffsCalendarYear,
} from "./";

export const TimeOffRequestRouter = () => {
  return (
    <Routes>
      <Route index element={<TimeOffRequestIndex />} />
      <Route path="request-vacations" element={<TimeOffRequestFormWrapper />} />
      <Route path="calendar" element={<TimeOffsCalendarYear />} />
      <Route path="requests-status" element={<TimeOffRequestsCollaborator />} />
      <Route
        path="user-profile"
        element={<TimeOffRequestsCollaboratorInfo />}
      />
      <Route path="approval" element={<TimeOffRequestsAll />} />
      <Route path="create" element={<TimeOffRequestCreate />} />
      <Route path=":id" element={<TimeOffRequestDetailView />} />
    </Routes>
  );
};
