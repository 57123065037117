import { useCallback } from "react";
import { useAppSelector } from "./useAppSelector";
import { getOptionsFromProperties } from "../shared/helpers";
import { HARBOR_COORDS, MONTEJO_COORDS, URBAN_COORDS } from "../shared";
import { BRANCH_CASH_LIMITS } from "../shared/constants/branch-cash-limits";

export const useBranchHook = () => {
  const { branches } = useAppSelector((state) => state.branches);

  const getBranchById = useCallback(
    (id: string) => {
      return branches.find((branch) => branch.id === id);
    },
    [branches]
  );

  const getBranchNameById = useCallback(
    (id?: string | null) => {
      if (!id) return undefined;
      return branches.find((branch) => branch.id === id)?.name;
    },
    [branches]
  );

  const getBranchByName = useCallback(
    (name?: string | null) => {
      if (!name) return undefined;
      return branches.find(
        (branch) => branch.name.toLowerCase() === name.toLowerCase()
      );
    },
    [branches]
  );

  const getBranchOptions = useCallback(() => {
    return getOptionsFromProperties(branches, "name", "id");
  }, [branches]);

  const getBranchCoordinatesByName = useCallback((name: string) => {
    if (name === "Urban") return URBAN_COORDS;
    if (name === "Montejo") return MONTEJO_COORDS;
    if (name === "Harbor") return HARBOR_COORDS;
    else
      return {
        lat: 0,
        lng: 0,
      };
  }, []);

  const branchExistsByName = useCallback(
    (name: string) => {
      return branches.find((branch) => branch.name === name);
    },
    [branches]
  );

  const getBranchCashLimit = useCallback((id: string) => {
    const branch = branches.find((branch) => branch.id === id);
    const branchName = branch?.name;

    console.log({ branchName });

    if (branchName === "Urban") return BRANCH_CASH_LIMITS.URBAN;
    if (branchName === "Montejo") return BRANCH_CASH_LIMITS.MONTEJO;
    if (branchName === "Harbor") return BRANCH_CASH_LIMITS.HARBOR;

    return 0;
  }, []);

  return {
    getBranchById,
    getBranchNameById,
    getBranchByName,
    branches,
    getBranchOptions,
    getBranchCoordinatesByName,
    branchExistsByName,
    getBranchCashLimit,
  };
};
