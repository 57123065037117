import { useEffect, useRef, useState } from "react";

interface FetchRecordsOptions {
  service: any; // This will be a service class that handles fetching
  params: any; // Parameters to be sent to the service
  limit?: number; // Optional limit for pagination
}

export const useFetchRecords = <T>({
  service,
  params,
  limit = 200,
}: FetchRecordsOptions) => {
  const [records, setRecords] = useState<T[]>([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(0);
  const initialFetch = useRef(false); // Track the first fetch

  const fetchRecords = async (pageNumber: number) => {
    setLoading(true);
    try {
      const data = await service.getAll({
        ...params,
        page: pageNumber,
        limit,
      });

      setCount(data.meta.total);
      setRecords((prev) => [...prev, ...data.data]);

      // Check if there are more records to fetch
      setHasMore(pageNumber * limit < data.meta.total);
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoading(false);
    }
  };

  const loadNextPage = async () => {
    if (hasMore) {
      const nextPage = page + 1;
      await fetchRecords(nextPage);
      setPage(nextPage);
    }
  };

  useEffect(() => {
    // Prevent double-fetching in development strict mode
    if (!initialFetch.current) {
      fetchRecords(page);
      initialFetch.current = true;
    }
  }, [page]); // Only depend on `page`

  return {
    records,
    loading,
    hasMore,
    loadNextPage,
    count,
  };
};
