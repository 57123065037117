import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";
import { ICollaborator } from "../../shared/types/collaborators.types";
import { CustomAvatar } from "../avatar/CustomAvatar";
import { useAppSelector } from "../../hooks/useAppSelector";

type Props = {
  selectedCollaborator?: string;
  handleSelectChange: (event: SelectChangeEvent<string>) => void;
  sx?: SxProps<Theme>;
  shownCollaborators?: "all" | "active";
};
export const CollaboratorSelect: React.FC<Props> = ({
  selectedCollaborator,
  handleSelectChange,
  shownCollaborators = "all",
  sx,
}) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);

  const sortedCollaborators = ([...collaborators] as ICollaborator[]).sort(
    (a, b) => a.col_code.localeCompare(b.col_code)
  );

  const filteredCollaborators =
    shownCollaborators === "active"
      ? sortedCollaborators.filter((collaborator) => collaborator.isActive)
      : sortedCollaborators;

  return (
    <FormControl fullWidth sx={sx}>
      <InputLabel id="collaborator-label">Collaborator</InputLabel>
      <Select
        labelId="collaborator-label"
        value={selectedCollaborator}
        onChange={handleSelectChange}
        label="Collaborator"
      >
        {filteredCollaborators.map((collaborator: ICollaborator) => {
          return (
            <MenuItem key={collaborator.id} value={collaborator.id}>
              <Box display="flex" alignItems="center">
                <CustomAvatar collaborator={collaborator} />
                <Box ml={1}>{collaborator.col_code}</Box>
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
