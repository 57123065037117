import React, { useState, useEffect, useMemo } from "react";

import { TimeOffsCalendarMonth } from "../components/";
import { Box, Button } from "@mui/material";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { convertTimeOffOverviewToDateTimeOffRequests } from "../../../../helpers/timeOffRequestsHelpers";

type VacationCalendarYearProps = {
  year?: number;
};

export const TimeOffsCalendarYear = ({
  year: initialYear,
}: VacationCalendarYearProps) => {
  const [isLoading, setisLoading] = useState(true);

  const { collaboratorsTimeOffsOverview } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const { collaborators } = useAppSelector((state) => state.collaborator);

  const currentDate = useMemo(() => new Date(), []);
  const currentYear = initialYear || currentDate.getFullYear();

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const handleYearChange = (change: number) => {
    console.log("CLICKED");
    setisLoading(true);
    setSelectedYear((prevYear) => prevYear + change);
    setisLoading(false);
  };

  useEffect(() => {
    setisLoading(true);
    const todayMonth = currentDate.getMonth();
    const todayYear = currentDate.getFullYear();

    if (selectedYear === todayYear) {
      const currentMonthElement = document.getElementById(
        `${todayYear}-${todayMonth}`
      );
      currentMonthElement &&
        currentMonthElement.scrollIntoView({ behavior: "smooth" });
    }
    setTimeout(() => {
      setisLoading(false); // Simulate loading time or wait for processing
    }, 300);
  }, [currentDate, selectedYear]);

  const timeOffDates = useMemo(() => {
    setisLoading(true);
    if (collaborators.length === 0) return [];

    const dates = convertTimeOffOverviewToDateTimeOffRequests(
      collaborators,
      collaboratorsTimeOffsOverview
    );

    setisLoading(false);
    return dates;
  }, [collaborators, collaboratorsTimeOffsOverview]);

  console.log({ isLoading });

  return (
    <>
      <Box display="flex" justifyContent="center" margin={4}>
        <Button onClick={() => handleYearChange(-1)} disabled={isLoading}>
          Previous
        </Button>
        <Button onClick={() => handleYearChange(1)} disabled={isLoading}>
          Next
        </Button>
      </Box>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Box>
          {Array.from({ length: 12 }, (_, index) => index).map((month) => (
            <TimeOffsCalendarMonth
              month={month}
              year={selectedYear}
              key={`${selectedYear}-${month}`}
              timeOffDates={timeOffDates}
            />
          ))}
        </Box>
      )}
      <Box display="flex" justifyContent="center" margin={4}>
        <Button onClick={() => handleYearChange(-1)} disabled={isLoading}>
          Previous
        </Button>
        <Button onClick={() => handleYearChange(1)} disabled={isLoading}>
          Next
        </Button>
      </Box>
    </>
  );
};
