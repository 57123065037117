import { Box, MenuItem, TextField } from "@mui/material";
import React from "react";
import { getActiveCollaborators } from "../../../../helpers";
import { ICollaborator } from "../../../../shared/types/collaborators.types";
import {
  TimeOffStatus,
  TimeOffType,
} from "../../../../shared/types/time-off-request.types";
import { useAppSelector } from "../../../../hooks/useAppSelector";

type Props = {
  filters: { [key: string]: string };
  handleFilterChange: (filterType: string, value: string) => void;
};

export const TimeOffRequestsFilters: React.FC<Props> = ({
  filters,
  handleFilterChange,
}) => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const activeCollaborators = getActiveCollaborators(collaborators);

  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <TextField
        select
        label="Colaborador"
        value={filters.collaborator}
        onChange={(e) => handleFilterChange("collaborator", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {activeCollaborators.map((collaborator: ICollaborator) => (
          <MenuItem key={collaborator.id} value={collaborator.id}>
            {collaborator.col_code}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Status"
        value={filters.status}
        onChange={(e) => handleFilterChange("status", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {Object.values(TimeOffStatus).map((status) => (
          <MenuItem key={status} value={status}>
            {status}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Tipo"
        value={filters.type}
        onChange={(e) => handleFilterChange("type", e.target.value)}
        style={{ flex: 1, margin: "0 16px" }}
      >
        <MenuItem value="">All</MenuItem>
        {Object.values(TimeOffType).map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
