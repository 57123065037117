import React, { useState } from "react";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { formatDatetime } from "../../../../shared/helpers";

import { CollaboratorSelect } from "../../../../components";
import { useAuthHook, useBranchHook } from "../../../../hooks";
import {
  useFormStatusHook,
  useSimplifiedBranchCashReconciliationHook,
} from "../../../../shared/hooks";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

type Props = {
  initialData: ISimplifiedBranchCashReconciliation;
};

export const SimplifiedBranchCashReconciliationForm = ({
  initialData,
}: Props) => {
  console.log("RERENDER");
  const [formValues, setFormValues] =
    useState<ISimplifiedBranchCashReconciliation>(initialData);
  const { getBranchNameById, getBranchCashLimit } = useBranchHook();
  const { saveSimplifiedBranchCashReconciliation } =
    useSimplifiedBranchCashReconciliationHook();
  const { isAdminOrManager } = useAuthHook();

  const handleCashChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: "cashInDrawerEnd" | "cashTransfer" | "qvetCashInDrawer"
  ) => {
    if (!initialData) return;
    const value = e.target.value === "" ? 0 : Number(e.target.value);
    const branchLimit = getBranchCashLimit(initialData.branchId);

    setFormValues((prev) => {
      if (field === "qvetCashInDrawer") {
        const cashInDrawerEnd = prev.cashInDrawerStart + value;
        const cashTransfer = Math.max(0, cashInDrawerEnd - branchLimit);
        const closingCash = Math.min(branchLimit, cashInDrawerEnd);
        return {
          ...prev,
          qvetCashInDrawer: value,
          cashInDrawerEnd,
          cashTransfer,
          closingCash,
        };
      }
      if (field === "cashInDrawerEnd") {
        console.log(branchLimit);
        return {
          ...prev,
          cashInDrawerEnd: value,
          cashTransfer: Math.max(0, value - branchLimit),
          closingCash: Math.min(branchLimit, value),
        };
      }
      return { ...prev, [field]: value };
    });
  };

  const handleSave = () => {
    const saveData = async () => {
      await saveSimplifiedBranchCashReconciliation(formValues);
    };
    saveData();
  };

  return (
    <>
      <Paper sx={{ p: 3, maxWidth: 800, mx: "auto", mt: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Branch Cash Reconciliation
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CollaboratorSelect
              selectedCollaborator={formValues.cashierId}
              handleSelectChange={(e) =>
                setFormValues((prev) => {
                  return {
                    ...prev,
                    cashierId: e.target.value,
                  };
                })
              }
              shownCollaborators="active"
              sx={{ height: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Branch"
              value={getBranchNameById(formValues.branchId)}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disabled={!isAdminOrManager}
                label="Transaction Date"
                value={dayjs(formValues.transactionDate)}
                format="DD-MMM-YYYY HH:mm"
                onChange={(value) =>
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      transactionDate: dayjs(value).toDate(),
                    };
                  })
                }
              />
            </LocalizationProvider>

            {/* <TextField
              fullWidth
              label="Transaction Date"
              value={formatDatetime(formValues.transactionDate)}
              onChange={(e) =>
                setFormValues((prev) => {
                  return {
                    ...prev,
                    transactionDate: dayjs(e.target.value).toDate(),
                  };
                })
              }
            /> */}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cash in Drawer Start"
              value={formValues.cashInDrawerStart}
              disabled
              InputProps={{
                startAdornment: <span>$</span>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Qvet cash in drawer"
              type="number"
              value={formValues.qvetCashInDrawer}
              onChange={(e) => handleCashChange(e, "qvetCashInDrawer")}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cash in Drawer End"
              type="number"
              value={formValues.cashInDrawerEnd}
              onChange={(e) => handleCashChange(e, "cashInDrawerEnd")}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Cash Transfer"
              type="number"
              value={formValues.cashTransfer}
              onChange={(e) => handleCashChange(e, "cashTransfer")}
              InputProps={{
                startAdornment: <span>$</span>,
              }}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Closing Cash"
              value={formValues.closingCash}
              disabled
              InputProps={{
                startAdornment: <span>$</span>,
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <pre>{JSON.stringify({ formValues, initialData }, null, 2)}</pre>
    </>
  );
};
