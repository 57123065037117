import {
  ISimplifiedBranchCashReconciliationResponse,
  ISimplifiedBranchCashReconciliationRequest,
  ISimplifiedBranchCashReconciliation,
} from "../shared/types";
import { BaseService } from "./base.service";

export class SimplifiedBranchCashReconciliationService extends BaseService<
  ISimplifiedBranchCashReconciliationResponse,
  ISimplifiedBranchCashReconciliationRequest
> {
  constructor() {
    super("/simplified-branch-cash-reconciliation");
  }

  public transformEntityToRequest(
    entity: ISimplifiedBranchCashReconciliation
  ): ISimplifiedBranchCashReconciliationRequest {
    return {
      ...entity,
      transactionDate: entity.transactionDate.toISOString(),
      transactionDatetime: entity.transactionDatetime.toISOString(),
    };
  }

  public transformResponseToEntity(
    response: ISimplifiedBranchCashReconciliationResponse
  ): ISimplifiedBranchCashReconciliation {
    return {
      ...response,
      transactionDate: new Date(response.transactionDate),
      transactionDatetime: new Date(response.transactionDatetime),
      branchId: response.branchId.id,
      cashierId: response.cashierId.id ?? response.cashierId,
      createdAt: new Date(response.createdAt),
      updatedAt: new Date(response.updatedAt),
    };
  }
}
