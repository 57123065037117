import { SimplifiedBranchCashReconciliationService } from "../../services";
import { createCRUDThunks } from "../../shared/helpers";
import {
  ISimplifiedBranchCashReconciliationRequest,
  ISimplifiedBranchCashReconciliationResponse,
} from "../../shared/types";

const service = new SimplifiedBranchCashReconciliationService();
export const simplifiedBranchCashReconciliationThunks = createCRUDThunks<
  ISimplifiedBranchCashReconciliationResponse,
  ISimplifiedBranchCashReconciliationRequest
>({
  service,
  resourceName: "simplified-branch-cash-reconciliation",
});
