import React from "react";
import { Grid } from "@mui/material";
import { CustomSwitch } from "../../../../components/inputs/CustomSwitch";

import { CustomSelect } from "../../../../components/inputs/CustomSelect";
import { getOptionsFromValues } from "../../../../shared/helpers";
import { getOptionsFromEnumOld } from "../../../../helpers";
import { useAppSelector } from "../../../../hooks";
import { PaymentType } from "../../../../shared/enums";
import { WebAppRole } from "../../../../shared/types";
import { CollaboratorFilters } from "../hooks/useFilteredSortedCollaboratorsHook";

type Props = {
  filters: CollaboratorFilters;
  updateFilter: (field: keyof CollaboratorFilters, value: any) => void;
};
export const CollaboratorListFilters = ({ filters, updateFilter }: Props) => {
  const { jobs } = useAppSelector((state) => state.jobs);
  const isRegisteredOptions = getOptionsFromValues([
    "Registered",
    "Unregistered",
  ]);

  const paymentOptions = getOptionsFromEnumOld(PaymentType);
  const roleOptions = getOptionsFromEnumOld(WebAppRole);
  const jobOptions = jobs.map((job) => ({ label: job.title, value: job.id! }));
  return (
    <>
      <Grid item xs={12} md={12 / 3} lg={12 / 6}>
        <CustomSwitch
          label="Show inactive"
          checked={filters.isActive === false}
          onChange={(e) => updateFilter("isActive", !e.target.checked)}
        />
      </Grid>
      <Grid item xs={12} md={12 / 3} xl={12 / 6}>
        <CustomSelect
          options={isRegisteredOptions}
          label="Is Registered"
          onChange={(e) =>
            updateFilter(
              "isRegistered",
              e.target.value === "Registered"
                ? true
                : e.target.value === "Unregistered"
                ? false
                : undefined
            )
          }
          withNoneOption={true}
        />
      </Grid>
      <Grid item xs={12} md={12 / 3} xl={12 / 6}>
        <CustomSelect
          options={paymentOptions}
          label="Payment type"
          onChange={(e) =>
            updateFilter("paymentType", e.target.value as PaymentType)
          }
          withNoneOption={true}
        />
      </Grid>
      <Grid item xs={12} md={12 / 3} xl={12 / 6}>
        <CustomSelect
          options={roleOptions}
          label="Role"
          onChange={(e) => updateFilter("role", e.target.value as WebAppRole)}
          withNoneOption={true}
        />
      </Grid>
      <Grid item xs={12} md={12 / 3} xl={12 / 6}>
        <CustomSelect
          options={jobOptions}
          label="Job"
          onChange={(e) => updateFilter("job", e.target.value as string)}
          withNoneOption={true}
        />
      </Grid>
    </>
  );
};
