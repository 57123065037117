import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useAuthHook } from "../../../hooks";
import {
  TimeOffRequestsCollaboratorsSummary,
  TimeOffRequestsUpcomingOverview,
} from "./components";
import { TimeOffRequestsCollaboratorInfo } from "./";

export const TimeOffRequestIndex: React.FC = () => {
  // *****GLOBALS***** //

  // *****STATES***** //

  // *****CUSTOM HOOKS***** //

  const isAuthorized = useAuthHook().isAdminOrManager;

  // *****HELPERS***** //

  // *****EFFECTS***** //

  useEffect(() => {}, []);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TimeOffRequestsUpcomingOverview />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TimeOffRequestsCollaboratorInfo />
        </Grid>

        {isAuthorized && (
          <Grid item xs={12} sm={12}>
            <TimeOffRequestsCollaboratorsSummary />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
