import { TimeOffRequest, TimeOffType } from "../types/time-off-request.types";
import { areDatesConsecutive } from "./date.helpers";

export const validateTimeOffRequest = (timeOffRequest: TimeOffRequest) => {
  const { requestedDays, timeOffType } = timeOffRequest;

  if (!areDatesConsecutive(requestedDays)) {
    return { error: "The dates are not consecutive" };
  }

  if (
    timeOffType !== TimeOffType.Vacation &&
    timeOffType !== TimeOffType.SickLeaveIMSS &&
    timeOffType !== TimeOffType.JustifiedAbsenceByCompany
  ) {
    return { error: "The time off type is not valid" };
  }

  return { success: true };
};
