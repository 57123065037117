// todo: Delete?

import React from "react";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { CustomAvatar } from "../../../../components/avatar/CustomAvatar";
import {
  getActiveCollaboratorsIds,
  getCollaboratorById,
  getFormattedMxDate,
} from "../../../../helpers";

export const TimeOffRequestsCollaboratorsSummary = () => {
  // @ts-ignore
  const { collaborators } = useAppSelector((state) => state.collaborator);
  const { collaboratorsTimeOffsOverview } = useAppSelector(
    (state) => state.timeOffRequestsReducer
  );

  const activeCollaboratorsIds = getActiveCollaboratorsIds(collaborators);

  const tableData = collaboratorsTimeOffsOverview
    .filter((collaboratorTimeOffOverview) =>
      activeCollaboratorsIds.includes(
        collaboratorTimeOffOverview.collaboratorId
      )
    )
    .map((collaboratorTimeOffOverview) => ({
      collaboratorId: collaboratorTimeOffOverview.collaboratorId,
      remainingLegalVacationDays:
        collaboratorTimeOffOverview.remainingLegalVacationDays,
      remainingcurrentYearVacationDays:
        collaboratorTimeOffOverview.remainingcurrentYearVacationDays,
      lastAnniversaryDate: collaboratorTimeOffOverview.lastAnniversaryDate,
    }));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Colaborador</TableCell>
            <TableCell>Total de vacaciones restantes</TableCell>
            <TableCell>Días de vacaciones restantes (legal)</TableCell>
            <TableCell>Días de vacaciones restantes (anticipo)</TableCell>
            <TableCell>Fecha de último aniversario</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row) => {
            const collaborator = getCollaboratorById(
              collaborators,
              row.collaboratorId
            );
            return (
              <TableRow key={row.collaboratorId}>
                <TableCell>
                  <CustomAvatar collaborator={collaborator!} />
                </TableCell>
                <TableCell>
                  {row.remainingLegalVacationDays +
                    row.remainingcurrentYearVacationDays}
                </TableCell>
                <TableCell>{row.remainingLegalVacationDays}</TableCell>
                <TableCell>{row.remainingcurrentYearVacationDays}</TableCell>
                <TableCell>
                  {getFormattedMxDate(row.lastAnniversaryDate)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
