import { createAsyncThunk } from "@reduxjs/toolkit";
import { TimeOffRequestService } from "../../services";
import { createCRUDThunks, handleAsyncThunkError } from "../../shared/helpers";
import {
  AxiosParams,
  TimeOffRequestRequest,
  TimeOffRequestResponse,
} from "../../shared/types";
import { RootState } from "../store";

const service = new TimeOffRequestService();

const basicTimeOffRequestThunks = createCRUDThunks<
  TimeOffRequestResponse,
  TimeOffRequestRequest
>({
  service,
  resourceName: "time-off-requests",
});

const getCollaboratorTimeOffOverview = createAsyncThunk(
  `time-off-requests/getCollaboratorTimeOffOverview`,
  async (
    { id, params }: { id: string; params?: AxiosParams },
    { rejectWithValue }
  ) => {
    try {
      const data = await service.getCollaboratorTimeOffOverview(id, params);
      return data.data;
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

const getCollaboratorsTimeOffOverview = createAsyncThunk(
  `time-off-requests/getCollaboratorsTimeOffOverview`,
  async (params: AxiosParams, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const activeCollaboratorId = state.authReducer.user?.uid;

      const data = await service.getCollaboratorsTimeOffOverview(params);
      const list = data.data;
      const activeCollaboratorTimeOffOverview = list.find(
        (item) => item.collaboratorId === activeCollaboratorId
      );

      console.log({ list, activeCollaboratorTimeOffOverview });

      return { list, activeCollaboratorTimeOffOverview };
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

export const TimeOffRequestThunks = {
  ...basicTimeOffRequestThunks,
  getCollaboratorsTimeOffOverview,
  getCollaboratorTimeOffOverview,
};
