import { formatToLabel } from "./stringHelpers";

export const getOptionsFromValues = (values: string[] | number[]) => {
  return values.map((value) => ({
    value,
    label: formatToLabel(value.toString()),
  }));
};

// export const getValuesFromType = <T extends string>(type: T): T[] =>
//   Object.values(type);

type Option<T extends string> = {
  value: T;
  label: string;
};

export const getOptionsFromEnumOld = <T extends string>(
  enumObject: Record<string, T>
): Option<T>[] =>
  Object.values(enumObject).map((value) => ({
    value,
    label: formatToLabel(value),
  }));

export const MONTHS_OPTIONS = [
  {
    label: "January",
    value: "0",
  },
  {
    label: "February",
    value: "1",
  },
  {
    label: "March",
    value: "2",
  },
  {
    label: "April",
    value: "3",
  },
  {
    label: "May",
    value: "4",
  },
  {
    label: "June",
    value: "5",
  },
  {
    label: "July",
    value: "6",
  },
  {
    label: "August",
    value: "7",
  },
  {
    label: "September",
    value: "8",
  },
  {
    label: "October",
    value: "9",
  },
  {
    label: "November",
    value: "10",
  },
  {
    label: "December",
    value: "11",
  },
];

export const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 21 }, (_, i) => currentYear - 10 + i);
  const options = getOptionsFromValues(years);
  return options;
};
