import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useBranchHook } from "../../../../../hooks";
import { ISimplifiedBranchCashReconciliation } from "../../../../../shared/types";
import { useSimplifiedBranchCashReconciliationHook } from "../../../../../shared/hooks";

import { SimplifiedBranchCashReconciliationForm } from "../../components";

export const SimplifiedBranchCashReconciliationBranch = () => {
  // *** GLOBALS *** //
  const { branchId } = useParams();

  // *** SELECTORS *** //

  // *** STATES *** //
  const [invalidBranch, setInvalidBranch] = useState(false);

  // *** DATA INITIALIZATION *** //
  const [initialData, setInitialData] =
    useState<ISimplifiedBranchCashReconciliation | null>(null);
  // *** CUSTOM HOOKS *** //
  const { getBranchInitialData } = useSimplifiedBranchCashReconciliationHook();

  const { branchExistsByName } = useBranchHook();
  // *** COMPUTED VALUES *** //
  // *** HANDLERS *** //
  // *** HELPERS *** //

  // *** EFFECTS *** //
  useEffect(() => {
    const branchExists = branchExistsByName(branchId!);
    if (!branchExists) {
      setInvalidBranch(true);
    }
  }, [branchId]);

  useEffect(() => {
    setInitialData(null);
    const getOpeningBranchCashReconciliation = async () => {
      const response = await getBranchInitialData(branchId!);
      console.log("response", response);
      setInitialData(response);
    };
    getOpeningBranchCashReconciliation();
  }, [branchId]);

  if (!initialData && !invalidBranch)
    return (
      <>
        <pre>{JSON.stringify(invalidBranch, null, 2)}</pre>
        Loading...
      </>
    );
  if (invalidBranch) return <>Invalid branch</>;

  return (
    <>
      {initialData && (
        <SimplifiedBranchCashReconciliationForm initialData={initialData} />
      )}
      <pre>{JSON.stringify({ initialData }, null, 2)}</pre>

      <pre>{JSON.stringify(invalidBranch, null, 2)}</pre>
    </>
  );
};
