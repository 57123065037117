import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  CollaboratorTimeOffOverview,
  TimeOffRequest,
  TimeOffRequestResponse,
} from "../../shared/types/time-off-request.types";
import { TimeOffRequestThunks } from "../thunks";

interface State {
  timeOffRequests: TimeOffRequestResponse[];
  activeTimeOffRequest: TimeOffRequestResponse | undefined;
  isLoading: boolean;
  collaboratorsTimeOffsOverview: CollaboratorTimeOffOverview[];
  activeCollaboratorTimeOffOverview: CollaboratorTimeOffOverview | undefined;
  isAllLoading: boolean;
}

const initialState: State = {
  timeOffRequests: [],
  activeTimeOffRequest: undefined,
  isLoading: true,
  collaboratorsTimeOffsOverview: [],
  activeCollaboratorTimeOffOverview: undefined,
  isAllLoading: true,
};

export const timeOffRequestsSlice = createSlice({
  name: "timeOffRequests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(TimeOffRequestThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      TimeOffRequestThunks.fetchItems.fulfilled,
      (state, action) => {
        state.timeOffRequests = action.payload;
        state.isLoading = false;
      }
    );
    builder.addCase(TimeOffRequestThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      TimeOffRequestThunks.getItemById.fulfilled,
      (state, action) => {
        state.activeTimeOffRequest = action.payload;
      }
    );
    builder.addCase(TimeOffRequestThunks.getItemById.rejected, (state) => {
      state.activeTimeOffRequest = undefined;
    });
    builder.addCase(
      TimeOffRequestThunks.createItem.fulfilled,
      (state, action) => {
        state.timeOffRequests.push(action.payload);
      }
    );
    builder.addCase(
      TimeOffRequestThunks.updateItem.fulfilled,
      (state, action) => {
        state.timeOffRequests = state.timeOffRequests.map((timeOffRequest) => {
          if (timeOffRequest.id === action.payload.id) {
            return action.payload;
          }
          return timeOffRequest;
        });
      }
    );
    builder.addCase(
      TimeOffRequestThunks.deleteItem.fulfilled,
      (state, action) => {
        state.timeOffRequests = state.timeOffRequests.filter(
          (timeOffRequest) => timeOffRequest.id !== action.payload
        );
      }
    );

    builder.addCase(
      TimeOffRequestThunks.getCollaboratorsTimeOffOverview.pending,
      (state) => {
        state.isAllLoading = true;
      }
    );
    builder.addCase(
      TimeOffRequestThunks.getCollaboratorsTimeOffOverview.fulfilled,
      (state, action) => {
        const { list, activeCollaboratorTimeOffOverview } = action.payload;
        console.log({ list, activeCollaboratorTimeOffOverview });
        state.collaboratorsTimeOffsOverview = list;
        state.activeCollaboratorTimeOffOverview =
          activeCollaboratorTimeOffOverview;

        state.isAllLoading = false;
      }
    );
    builder.addCase(
      TimeOffRequestThunks.getCollaboratorsTimeOffOverview.rejected,
      (state) => {
        state.isAllLoading = false;
      }
    );
    builder.addCase(
      TimeOffRequestThunks.getCollaboratorTimeOffOverview.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      TimeOffRequestThunks.getCollaboratorTimeOffOverview.fulfilled,
      (state, action) => {
        state.activeCollaboratorTimeOffOverview = action.payload;
        state.isLoading = false;
      }
    );
    // builder.addCase(
    //   TimeOffRequestThunks.getCollaboratorTimeOffOverview.rejected,
    //   (state) => {
    //     state.isLoading = false;
    //   }
    // );
  },
});

export default timeOffRequestsSlice.reducer;
