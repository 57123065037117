import dayjs, { Dayjs } from "dayjs";
import { convertUtcDateToMexicoTimeStartOfDay } from "./date.helpers";

// it should look like this $range:2024-01-01T00:00:00.000Z...2024-05-31T00:00:00.000Z
export const buildRangeParam = (
  date1: string | Date | Dayjs,
  date2: string | Date | Dayjs
) => {
  const parsedDate1 = dayjs(date1).toISOString();
  const parsedDate2 = dayjs(date2).toISOString();
  return `$range:${parsedDate1}...${parsedDate2}`;
};

export const buildWeekdRangeParamFromStartDate = (
  startingDate: string | Date | Dayjs
) => {
  const dayjsDate = dayjs(startingDate);
  const dayjsDateMexico = convertUtcDateToMexicoTimeStartOfDay(dayjsDate);
  const lastDay = dayjsDateMexico.endOf("week");

  return buildRangeParam(dayjsDateMexico, lastDay);
};
