import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISimplifiedBranchCashReconciliationResponse } from "../../shared/types";
import {
  simplifiedBranchCashReconciliationThunks,
  weekShiftThunks,
} from "../thunks";

interface State {
  simplifiedBranchCashReconciliations: ISimplifiedBranchCashReconciliationResponse[];
  activeSimplifiedBranchCashReconciliation?: ISimplifiedBranchCashReconciliationResponse;
  isLoading: boolean;
  isInitialLoading: boolean;
}

const initialState: State = {
  simplifiedBranchCashReconciliations: [],
  activeSimplifiedBranchCashReconciliation: undefined,
  isLoading: true,
  isInitialLoading: true,
};

export const simplifiedBranchCashReconciliationSlice = createSlice({
  name: "simplifiedBranchCashReconciliation",
  initialState,
  reducers: {
    setActiveSimplifiedBranchCashReconciliation: (
      state,
      action: PayloadAction<ISimplifiedBranchCashReconciliationResponse>
    ) => {
      state.activeSimplifiedBranchCashReconciliation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(weekShiftThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.fetchItems.fulfilled,
      (state, action) => {
        state.simplifiedBranchCashReconciliations = action.payload;
        state.isLoading = false;
        state.isInitialLoading = false;
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.fetchItems.rejected,
      (state) => {
        state.isLoading = false;
        state.isInitialLoading = false;
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.getItemById.fulfilled,
      (state, action) => {
        state.activeSimplifiedBranchCashReconciliation = action.payload;
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.getItemById.rejected,
      (state) => {
        state.activeSimplifiedBranchCashReconciliation = undefined;
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.createItem.fulfilled,
      (state, action) => {
        state.simplifiedBranchCashReconciliations.push(action.payload);
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.updateItem.fulfilled,
      (state, action) => {
        const index = state.simplifiedBranchCashReconciliations.findIndex(
          (simplifiedBranchCashReconciliation) =>
            simplifiedBranchCashReconciliation.id === action.payload.id
        );
        state.simplifiedBranchCashReconciliations[index] = action.payload;
      }
    );
    builder.addCase(
      simplifiedBranchCashReconciliationThunks.deleteItem.fulfilled,
      (state, action) => {
        state.simplifiedBranchCashReconciliations =
          state.simplifiedBranchCashReconciliations.filter(
            (simplifiedBranchCashReconciliation) =>
              simplifiedBranchCashReconciliation.id !== action.payload
          );
      }
    );
  },
});

export default simplifiedBranchCashReconciliationSlice.reducer;
